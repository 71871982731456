import React, {useEffect, useState} from 'react';

import { Grid, IconButton, TextField, Typography } from "@mui/material";
import CloseIcon from '@mui/icons-material/Close';
import DoneIcon from '@mui/icons-material/Done';
import EditIcon from '@mui/icons-material/Edit';

interface EditFieldsProps {
  displayFunction?: ((val: any) => string) | null
  fullWidth?: boolean,
  options?: JSX.Element[] | null,
  update: (value: string | number, key: string) => Promise<void>,
  value: string | number,
  valueKey: string, 
}

export const EditFields = ({
  displayFunction = null,
  fullWidth = false,
  options = null,
  update,
  value,
  valueKey,
}: EditFieldsProps) => {
  const [edit, setEdit] = useState(false);
  const [field, setField] = useState<number | string>('');

  useEffect(()=> {
    setField(value);
  }, [value])

  const updateEdit = (): void => {
    setField(value);
    setEdit(!edit);
  }

  const updateValue = (e: React.ChangeEvent<HTMLInputElement>) => {
    setField(e.target.value);
  }

  const handleSave = async (): Promise<void> => {
    await update(field, valueKey);
    setEdit(!edit);
  }

  return (
    <Grid 
      item
      xs={fullWidth ? 12 : 6} 
      md={fullWidth ? 12 : 8} 
      sx={{display: 'flex', alignItems: 'center'}}
    >
      { !edit ? (
        <>
          <Typography 
            variant={fullWidth ? 'h5' : 'body1'}
          >
            {displayFunction ? displayFunction(field) : field}
          </Typography>
          <IconButton onClick={updateEdit}>
            <EditIcon fontSize="small"/>
          </IconButton>
        </>
      ) : (
        <>
          { options ? (
            <TextField
              variant="outlined"
              color="skillMapping"
              value={field}
              onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                updateValue(event);
              }}
              select
              fullWidth
            >
              {options}
            </TextField>
          ) : (
            <TextField
              fullWidth
              color='skillMapping'
              defaultValue={field}
              inputProps={{style: {fontSize: fullWidth ? '1.5rem' : '1rem'}}}
              onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                updateValue(event);
              }}
            />
          )}
          <IconButton onClick={handleSave}>
            <DoneIcon />
          </IconButton>
          <IconButton onClick={updateEdit}>
            <CloseIcon />
          </IconButton>
        </>
      )}  
      </Grid>

  )
}
