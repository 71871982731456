import { alpha, darken, styled, Grid } from "@mui/material";
import { FixMeLater } from "../../../../any";

const PREFIX = "ReviewSkillsPage";

export const classes = {
  container: `${PREFIX}-container`,
  headingContainer: `${PREFIX}-headingContainer`,
  programInfoContainer: `${PREFIX}-programInfoContainer`,
  flex: `${PREFIX}-flex`,
  boldText: `${PREFIX}-boldText`,
  programInfo: `${PREFIX}-programInfo`,
  userInfo: `${PREFIX}-userInfo`,
  skillsText: `${PREFIX}-skillsText`,
  skillsContainer: `${PREFIX}-skillsContainer`,
  hardSkillsText: `${PREFIX}-hardSkillsText`,
  softSkillsText: `${PREFIX}-softSkillsText`,
  hardSkillChip: `${PREFIX}-hardSkillChip`,
  softSkillChip: `${PREFIX}-softSkillChip`,
};

export const CustomGrid = styled(Grid)(({ theme }: FixMeLater) => ({
  padding: "10px",
  borderRadius: "4px",
  backgroundColor: "#F3F3F3",
  boxShadow:
    "0px 3px 1px -2px rgba(0,0,0,0.2), 0px 2px 2px 0px rgba(0,0,0,0.14), 0px 1px 5px 0px rgba(0,0,0,0.12)",
  [`& .${classes.container}`]: {
    height: "70vh",
    display: "flex",
    flexDirection: "column",
  },
  [`& .${classes.headingContainer}`]: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    marginBottom: "20px",
  },
  [`& .${classes.programInfoContainer}`]: {
    marginBottom: "40px",
  },
  [`& .${classes.flex}`]: {
    display: "flex",
  },
  [`& .${classes.boldText}`]: {
    fontWeight: 600,
  },
  [`& .${classes.programInfo}`]: {
    margin: "10px 0px 30px",
  },
  [`& .${classes.userInfo}`]: {
    display: "flex",
    alignItems: "center",
    position: "relative",
    bottom: "5px",
    "& .MuiAvatar-root": {
      marginRight: "10px",
      fontSize: "14px",
      height: "30px",
      width: "30px",
    },
  },
  [`& .${classes.skillsText}`]: {
    position: "relative",
    top: "1px",
    fontSize: "14px",
    fontStyle: "italic",
    color: theme.palette.grey.main,
    marginLeft: "10px",
  },
  [`& .${classes.skillsContainer}`]: {
    maxHeight: "95%",
    overflowY: "scroll",
    "@media (max-width: 1200px)": {
      maxHeight: "92%",
    },
  },
  [`& .${classes.hardSkillsText}`]: {
    display: "flex",
    fontSize: "14px",
    color: theme.palette.skillMapping.secondary,
  },
  [`& .${classes.softSkillsText}`]: {
    display: "flex",
    fontSize: "14px",
    color: theme.palette.skillMapping.main,
    marginLeft: "10px",
  },
  [`& .${classes.hardSkillChip}`]: {
    margin: "5px",
    borderRadius: "6px",
    backgroundColor: alpha(theme.palette.skillMapping.secondary, 0.4),
    color: darken(theme.palette.skillMapping.secondary, 0.4),
    boxShadow:
      "0px 3px 1px -2px rgba(0,0,0,0.2), 0px 2px 2px 0px rgba(0,0,0,0.14), 0px 1px 5px 0px rgba(0,0,0,0.12)",
    "&:hover": {
      backgroundColor: alpha(theme.palette.skillMapping.secondary, 0.4),
    },
  },
  [`& .${classes.softSkillChip}`]: {
    margin: "5px",
    borderRadius: "6px",
    backgroundColor: alpha(theme.palette.skillMapping.main, 0.4),
    color: darken(theme.palette.skillMapping.main, 0.4),
    boxShadow:
      "0px 3px 1px -2px rgba(0,0,0,0.2), 0px 2px 2px 0px rgba(0,0,0,0.14), 0px 1px 5px 0px rgba(0,0,0,0.12)",
    "&:hover": {
      backgroundColor: alpha(theme.palette.skillMapping.main, 0.4),
    },
  },
}));
