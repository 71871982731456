import { styled, Grid } from "@mui/material";
import { linearProgressClasses } from "@mui/material/LinearProgress";
import { GridToolbarContainer } from "@mui/x-data-grid";
import { FixMeLater } from "../../../any";

const PREFIX = "DataGridComponent";

export const classes = {
  flex: `${PREFIX}-flex`,
  headerText: `${PREFIX}-headerText`,
  headerDivider: `${PREFIX}-headerDivider`,
  searchBar: `${PREFIX}-searchBar`,
  filterButton: `${PREFIX}-filterButton`,
  addButton: `${PREFIX}-addButton`,
  noSkillsText: `${PREFIX}-noSkillsText`,
  skillsText: `${PREFIX}-skillsText`,
  progressBar: `${PREFIX}-progressBar`,
  newChip: `${PREFIX}-newChip`,
};

export const CustomToolbarContainer = styled(GridToolbarContainer)(
  ({ theme }: FixMeLater) => ({
    justifyContent: "space-between",
    margin: "20px 0px",
    [`& .${classes.flex}`]: {
      display: "flex",
    },
    [`& .${classes.headerText}`]: {
      display: "flex",
      "@media (max-width: 1200px)": {
        marginBottom: "20px",
      },
    },
    [`& .${classes.headerDivider}`]: {
      margin: "5px 20px",
      border: "1.5px solid black",
    },
    [`& .${classes.searchBar}`]: {
      width: "400px",
      padding: "2px",
      "@media (max-width: 1300px)": {
        width: "350px",
      },
    },
    [`& .${classes.filterButton}`]: {
      color: theme.palette.skillMapping.main,
      border: "1px solid",
      borderColor: theme.palette.skillMapping.main,
      borderRadius: "16px",
      padding: "4px 16px",
      margin: "0px 20px",
      "&:hover": {
        cursor: "pointer",
      },
    },
    [`& .${classes.addButton}`]: {
      borderRadius: "16px",
      padding: "4px 16px",
      "&:hover": {
        cursor: "pointer",
      },
    },
  })
);

export const DataGridComponent = styled(Grid)(({ theme }: FixMeLater) => ({
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  [`& .${classes.noSkillsText}`]: {
    color: theme.palette.red.main,
    fontSize: "0.875rem",
  },
  [`& .${classes.skillsText}`]: {
    color: theme.palette.black.main,
    fontSize: "0.875rem",
  },
  [`& .${classes.progressBar}`]: {
    width: "90%",
    height: 7,
    borderRadius: 5,
    [`&.${linearProgressClasses.colorPrimary}`]: {
      backgroundColor: theme.palette.skillMapping.main,
    },
    [`& .${linearProgressClasses.bar}`]: {
      borderRadius: 5,
      backgroundColor: theme.palette.skillMapping.secondary,
    },
  },
  [`& .${classes.newChip}`]: {
    display: "flex",
    backgroundColor: theme.palette.skillMapping.secondary,
    color: theme.palette.skillMapping.contrastText,
    "& .MuiChip-icon": {
      color: theme.palette.skillMapping.contrastText,
    },
    "& .MuiChip-label": {
      paddingTop: "1px",
    },
  },
}));

export const CustomGridOverlay = styled("div")(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "center",
  height: "100%",
  "& .ant-empty-img-1": {
    fill: theme.palette.mode === "light" ? "#aeb8c2" : "#262626",
  },
  "& .ant-empty-img-2": {
    fill: theme.palette.mode === "light" ? "#f5f5f7" : "#595959",
  },
  "& .ant-empty-img-3": {
    fill: theme.palette.mode === "light" ? "#dce0e6" : "#434343",
  },
  "& .ant-empty-img-4": {
    fill: theme.palette.mode === "light" ? "#fff" : "#1c1c1c",
  },
  "& .ant-empty-img-5": {
    fillOpacity: theme.palette.mode === "light" ? "0.8" : "0.08",
    fill: theme.palette.mode === "light" ? "#f5f5f5" : "#fff",
  },
}));
