import { Grid, Typography, Divider, Button } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";

import {
  GridToolbarQuickFilter,
  GridToolbarFilterButton,
} from "@mui/x-data-grid";

import { CustomToolbarContainer, classes } from "./style";

interface ToolbarProps {
  institution: string;
  handleOpen: () => void;
  setFilterButtonEl: React.Dispatch<
    React.SetStateAction<HTMLButtonElement | null>
  >;
}

export const GridCustomToolbar = ({
  institution,
  handleOpen,
  setFilterButtonEl,
}: ToolbarProps) => {
  return (
    <CustomToolbarContainer>
      <Grid container className={classes.flex}>
        <Grid item xs={12} lg={5} className={classes.headerText}>
          <Typography variant="h5">Programs</Typography>
          <Divider
            orientation="vertical"
            variant="middle"
            flexItem
            className={classes.headerDivider}
          />
          <Typography variant="h5">{institution}</Typography>
        </Grid>
        <Grid item xs={12} lg={7} className={classes.flex}>
          <GridToolbarQuickFilter className={classes.searchBar} />
          <GridToolbarFilterButton
            className={classes.filterButton}
            ref={setFilterButtonEl}
          />
          <Button
            className={classes.addButton}
            color="skillMapping"
            variant="contained"
            size="small"
            startIcon={<AddIcon />}
            onClick={handleOpen}
          >
            Add Program
          </Button>
        </Grid>
      </Grid>
    </CustomToolbarContainer>
  );
};
