import { useNavigate } from "react-router-dom";

import { Grid, Typography, LinearProgress, Button, Chip } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import NewIcon from "@mui/icons-material/NewReleases";
import {
  GridColDef,
  GridRenderCellParams,
  getGridStringOperators,
  getGridBooleanOperators,
} from "@mui/x-data-grid";

import { DataGridComponent, classes } from "./style";

const SkillsComponent = (props: GridRenderCellParams<any>): JSX.Element => {
  const navigate = useNavigate();

  const { value } = props;
  const numSkills = value.hard.length + value.soft.length;

  return (
    <DataGridComponent container>
      <Grid item xs={3}>
        <Typography
          className={numSkills > 0 ? classes.skillsText : classes.noSkillsText}
        >
          {numSkills} skills
        </Typography>
      </Grid>
      <Grid item xs={9}>
        {numSkills > 0 ? (
          <LinearProgress
            className={classes.progressBar}
            variant="determinate"
            value={(value.hard.length / numSkills) * 100}
          />
        ) : (
          <Button
            color="red"
            variant="contained"
            size="small"
            startIcon={<AddIcon />}
            onClick={() =>
              navigate(`/add-skills/${value.programId}/occupations`)
            }
          >
            Add Skills
          </Button>
        )}
      </Grid>
    </DataGridComponent>
  );
};

const DateComponent = (props: GridRenderCellParams<any>): JSX.Element => {
  const { value } = props;

  return (
    <DataGridComponent container>
      <Grid item xs={12}>
        {value
          ? new Date(value).toLocaleDateString("en-US", {
              month: "short",
              day: "numeric",
              year: "numeric",
            })
          : "N/A"}
      </Grid>
    </DataGridComponent>
  );
};

const IsNewComponent = (props: GridRenderCellParams<any>): JSX.Element => {
  const { value } = props;

  return (
    value && (
      <DataGridComponent container>
        <Grid item xs={12}>
          <Chip
            className={classes.newChip}
            icon={<NewIcon fontSize="small" />}
            label="NEW"
          />
        </Grid>
      </DataGridComponent>
    )
  );
};

export const columns: GridColDef[] = [
  {
    field: "programName",
    headerName: "Program Name",
    width: 250,
    filterOperators: getGridStringOperators().filter((op) =>
      ["contains", "equals", "startsWith"].includes(op.value)
    ),
    renderHeader: () => <strong>Program Name</strong>,
  },
  {
    field: "field",
    headerName: "Field",
    width: 200,
    filterOperators: getGridStringOperators().filter((op) =>
      ["contains", "equals", "startsWith"].includes(op.value)
    ),
    renderHeader: () => <strong>Field</strong>,
  },
  {
    field: "type",
    headerName: "Credential Type",
    width: 150,
    filterOperators: getGridStringOperators().filter((op) =>
      ["contains", "equals", "startsWith"].includes(op.value)
    ),
    renderHeader: () => <strong>Credential Type</strong>,
  },
  {
    field: "skills",
    headerName: "Skills",
    width: 350,
    filterable: false,
    renderHeader: () => <strong>Skills</strong>,
    renderCell: SkillsComponent,
    valueGetter: ({ value, row }) => {
      return { hard: value.hard, soft: value.soft, programId: row.programId };
    },
  },
  {
    field: "dateEdited",
    headerName: "Date Edited",
    width: 150,
    filterable: false,
    renderHeader: () => <strong>Date Edited</strong>,
    renderCell: DateComponent,
  },
  {
    field: "dateAdded",
    headerName: "Date Added",
    width: 150,
    filterable: false,
    renderHeader: () => <strong>Date Added</strong>,
    renderCell: DateComponent,
  },
  {
    field: "isNew",
    headerName: "",
    width: 100,
    filterOperators: getGridBooleanOperators().filter((op) =>
      ["is"].includes(op.value)
    ),
    renderCell: IsNewComponent,
    valueGetter: ({ value, row }) => {
      var todaysDate = new Date();

      if (
        new Date(row.dateAdded).setHours(0, 0, 0, 0) ==
        todaysDate.setHours(0, 0, 0, 0)
      ) {
        // If date added equals today's date, mark as isNew
        return true;
      }
      return false;
    },
  },
];
