import React, { useState } from 'react';

import { Box, IconButton, InputAdornment, List, ListItem, TextField, ThemeProvider, Typography, createTheme } from '@mui/material';
import Tooltip from '@mui/material/Tooltip';
import HttpsIcon from '@mui/icons-material/Https';
import { Visibility, VisibilityOff } from '@mui/icons-material';

interface PasswordInputProps {
  label: string;
  placeholder: string;
  style: any;
  onChange: any;
  onKeyPress: any;
  tooltipIcons: any;
  passwordReset: any;
}

const PasswordInput = ({ label, placeholder, style, onChange, onKeyPress, tooltipIcons, passwordReset }: PasswordInputProps): JSX.Element => {
  const [showPassword, setShowPassword] = useState(false);
  const [showTooltip, setShowTooltip] = useState(false);
  const handleMouseDownPassword = (event: any) => {
    event.preventDefault();
  };
  const handleClickShowPassword = () => setShowPassword(show => !show);
  const handleCopyPaste = (e: any) => {e.preventDefault();};

  const tooltipTheme = createTheme({
    components: {
      MuiTooltip: {
        styleOverrides: {
          tooltip: {
            padding: '0',
            backgroundColor: 'white',
            color: '#2f54a9',
            filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32)) !important',
          }
        }
      }
    }
  });

  return (
    <Box>
      {passwordReset ?
      (<ThemeProvider theme={tooltipTheme}>
        <Tooltip
        title={
          <React.Fragment>
            <div
              style={{
                padding: '10px',
                color: 'white',
                backgroundColor: 'color(srgb 0.1833 0.328 0.6647)'
              }}
            >
              <Typography color="inherit">The password must meet the following requirements:</Typography>
            </div>
            <div
              style={{
                padding: '10px',
                color: 'color(srgb 0.1833 0.328 0.6647)'
              }}
            >
              <List>
                <ListItem>
                  {tooltipIcons.passwordLengthIcon}
                  <Typography>8 or more characters</Typography>
                </ListItem>
                <ListItem>
                  {tooltipIcons.passwordLowercaseIcon}
                  <Typography>Lowercase letter</Typography>
                </ListItem>
                <ListItem>
                  {tooltipIcons.passwordUppercaseIcon}
                  <Typography>Capital letter</Typography>
                </ListItem>
                <ListItem>
                  {tooltipIcons.passwordNumberIcon}
                  <Typography>Number</Typography>
                </ListItem>
                <ListItem>
                  {tooltipIcons.passwordSpecialCharacterIcon}
                  <Typography>Special character</Typography>
                </ListItem>
              </List>
            </div>
          </React.Fragment>
        }
        placement="bottom-start"
        arrow
        open={showTooltip}
        style={{
          backgroundColor: '#f5f5f9 !important',
          color: 'rgba(0, 0, 0, 0.87) !important',
          maxWidth: 220,
          border: '1px solid #dadde9',
        }}
      >
      <TextField
        required
        label={label}
        placeholder={placeholder}
        type={showPassword ? 'text' : 'password'}
        style={style}
        autoComplete="current-password"
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <HttpsIcon />
            </InputAdornment>
          ),
          endAdornment: (
            <InputAdornment position="end">
              <IconButton
                aria-label="toggle password visibility"
                onClick={handleClickShowPassword}
                onMouseDown={handleMouseDownPassword}
                edge="end"
              >
                {showPassword ? <VisibilityOff /> : <Visibility />}
              </IconButton>
            </InputAdornment>
          ),
        }}
        variant="outlined"
        onChange={onChange}
        onFocus={() => {setShowTooltip(true)}}
        onBlur={() => {setShowTooltip(false)}}
        onKeyPress={onKeyPress}
        onCopy={handleCopyPaste}
        onPaste={handleCopyPaste}
      />
      </Tooltip>
      </ThemeProvider>
      ) : (
      <TextField
        required
        label={label}
        placeholder={placeholder}
        type={showPassword ? 'text' : 'password'}
        style={style}
        autoComplete="current-password"
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <HttpsIcon />
            </InputAdornment>
          ),
          endAdornment: (
            <InputAdornment position="end">
              <IconButton
                aria-label="toggle password visibility"
                onClick={handleClickShowPassword}
                onMouseDown={handleMouseDownPassword}
                edge="end"
              >
                {showPassword ? <VisibilityOff /> : <Visibility />}
              </IconButton>
            </InputAdornment>
          ),
        }}
        variant="outlined"
        onChange={onChange}
        onFocus={() => {setShowTooltip(true)}}
        onBlur={() => {setShowTooltip(false)}}
        onKeyPress={onKeyPress}
      />
      )}
    </Box>
  );
};

export default PasswordInput;
