import { Grid } from "@mui/material";

import { SkillType } from "../../helper";
import { OccupationType } from "../occupationSkills/helper";
import { CustomGrid, classes } from "./style";

import { useSetState } from 'react-use';
import Joyride, { CallBackProps, STATUS, Step } from 'react-joyride';
import { FixMeLater } from "../../../../any";

interface State {
  run: boolean;
  stepList: Step[];
}

export interface SuggestedSkillsPageProps {
  selectedSkills: SkillType[];
  handleSelectSkill: (skill: SkillType) => void;
  handleSelectAll: (occupation: OccupationType) => void;
  handleClearAll: (occupation: OccupationType) => void;
  tutorial: boolean;
  setTutorial: FixMeLater;
}

export const SuggestedSkills = ({
  selectedSkills,
  handleSelectSkill,
  handleSelectAll,
  handleClearAll,
  tutorial,
  setTutorial
}: SuggestedSkillsPageProps) => {
  const [{ stepList }, setState] = useSetState<State>({
    run: tutorial,
    stepList: [
      {
        content: <div>
            <p>
              In this step, you can select more skills based on a list of suggestions for the selected program.
            </p>
          </div>,
        placement: 'center',
        target: 'body',
      },
      {
        content: <div>
            <p>
              This panel contains a list of suggested skills that may be applicable to the selected program.
              Please select any skills from this list that can be acquired through the selected program.
            </p>
          </div>,
        spotlightPadding: 0,
        placement: 'right',
        target: '#suggestedSkills',
      },
      {
        content: <div>
            <p>
              This panel contains a list of skills that you have selected. You may remove skills from 
              this panel at any time by clicking the 'x' icon on the skill's tag.
            </p>
          </div>,
        spotlightPadding: 0,
        placement: 'left',
        target: '#skillsList',
      },
      {
        content: <div>
            <p>
              Once you have completed your selections, you can continue to the next step.
            </p>
          </div>,
        spotlightPadding: 0,
        placement: 'top',
        target: '#nextButton',
      },
      {
        content: <div>
            <p>
              You can return to the previous step at any time. All of your changes will be saved.
            </p>
          </div>,
        spotlightPadding: 0,
        placement: 'top',
        target: '#previousButton',
      },
      {
        content: <div>
            <p>
              You may also skip this step if you do not wish to select any skills in this step.
            </p>
          </div>,
        spotlightPadding: 0,
        placement: 'top',
        target: '#skipButton',
      },
    ],
  });


  const handleJoyrideCallback = (data: CallBackProps) => {
    const { status, type } = data;
    const finishedStatuses: string[] = [STATUS.FINISHED, STATUS.SKIPPED];

    if (finishedStatuses.includes(status)) {
      setTutorial(false);
    }
  };
  
  return (
    <CustomGrid container columnSpacing={2}>
    <Joyride
      callback={handleJoyrideCallback}
      continuous
      hideCloseButton
      run={tutorial}
      scrollToFirstStep
      showProgress
      showSkipButton
      steps={stepList}
      styles={{
        options: {
          arrowColor: '#EBEBEB',
          backgroundColor: '#EBEBEB',
          primaryColor: '#76A472',
          zIndex: 10000,
        },
      }}
    />
      <Grid item xs={6} className={classes.flex}>
        <Grid container className={classes.leftSide} id="suggestedSkills"></Grid>
      </Grid>
      <Grid item xs={6} className={classes.flex}>
        <Grid container className={classes.rightSide} id="skillsList"></Grid>
      </Grid>
    </CustomGrid>
  );
};
