import { Grid, Chip, Typography, Avatar } from "@mui/material";
import CircleIcon from "@mui/icons-material/FiberManualRecord";

import { ProgramsType } from "../../../Programs/dataGrid/dataRows";
import { SkillType } from "../../helper";
import { CustomGrid, classes } from "./style";

import { useSetState } from 'react-use';
import Joyride, { CallBackProps, STATUS, Step } from 'react-joyride';
import { FixMeLater } from "../../../../any";

interface State {
  run: boolean;
  stepList: Step[];
}

export interface ReviewSkillsPageProps {
  programInfo: ProgramsType;
  selectedSkills: SkillType[];
  tutorial: boolean;
  setTutorial: FixMeLater;
}

export const ReviewSkills = ({
  programInfo,
  selectedSkills,
  tutorial,
  setTutorial
}: ReviewSkillsPageProps) => {
  const [{ stepList }, setState] = useSetState<State>({
    run: tutorial,
    stepList: [
      {
        content: <div>
            <p>In this step, please review all of the skill selections and make any final changes before saving.</p>
          </div>,
        placement: 'center',
        target: 'body',
      },
      {
        content: <div>
            <p>
              This panel contains a list of skills that you have selected and categorized. You may remove skills from 
              this panel at any time by clicking the 'x' icon on the skill's tag.
            </p>
          </div>,
        spotlightPadding: 0,
        placement: 'bottom',
        target: '#skillsList',
      },
      {
        content: <div>
            <p>
              Once you have completed your selections, you can finalize and save your changes here.
            </p>
          </div>,
        spotlightPadding: 0,
        placement: 'top',
        target: '#nextButton',
      },
      {
        content: <div>
            <p>
              You can return to the previous step at any time. All of your changes will be saved.
            </p>
          </div>,
        spotlightPadding: 0,
        placement: 'top',
        target: '#previousButton',
      },
    ],
  });


  const handleJoyrideCallback = (data: CallBackProps) => {
    const { status, type } = data;
    const finishedStatuses: string[] = [STATUS.FINISHED, STATUS.SKIPPED];

    if (finishedStatuses.includes(status)) {
      setTutorial(false);
    }
  };
  
  return (
    <CustomGrid container>
    <Joyride
      callback={handleJoyrideCallback}
      continuous
      hideCloseButton
      run={tutorial}
      scrollToFirstStep
      showProgress
      showSkipButton
      steps={stepList}
      styles={{
        options: {
          arrowColor: '#EBEBEB',
          backgroundColor: '#EBEBEB',
          primaryColor: '#76A472',
          zIndex: 10000,
        },
      }}
    />
      <Grid item xs={12} className={classes.container}>
        <Grid container>
          <Grid item xs={12} className={classes.headingContainer}>
            <Typography className={classes.boldText}>
              {programInfo.field}, {programInfo.name}
            </Typography>
          </Grid>
          <Grid item xs={12} lg={8} className={classes.programInfoContainer}>
            <Grid container>
              <Grid item xs={12} md={6}>
                <Grid container className={classes.programInfo}>
                  <Grid item xs={6} className={classes.boldText}>
                    Field:
                  </Grid>
                  <Grid item xs={6}>
                    {programInfo.field}
                  </Grid>
                </Grid>
                <Grid container className={classes.programInfo}>
                  <Grid item xs={6} className={classes.boldText}>
                    Type:
                  </Grid>
                  <Grid item xs={6}>
                    {programInfo.type}
                  </Grid>
                </Grid>
                <Grid container className={classes.programInfo}>
                  <Grid item xs={6} className={classes.boldText}>
                    Minimum Duration:
                  </Grid>
                  <Grid item xs={6}>
                    {programInfo.duration
                      ? programInfo.duration + " years"
                      : "N/A"}
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12} md={6}>
                <Grid container className={classes.programInfo}>
                  <Grid item xs={6} md={4} className={classes.boldText}>
                    Last Edited:
                  </Grid>
                  <Grid item xs={6} md={8}>
                    <Grid container>
                      <Grid item xs={6}>
                        {programInfo.edited
                          ? new Date(programInfo.edited).toLocaleDateString(
                              "en-US",
                              {
                                month: "short",
                                day: "numeric",
                                year: "numeric",
                              }
                            )
                          : "N/A"}
                      </Grid>
                      <Grid item xs={6}>
                        {programInfo.edited && programInfo.lastEdited && (
                          <div className={classes.userInfo}>
                            {programInfo.lastEdited.photoUrl ? (
                              <Avatar src={programInfo.lastEdited.photoUrl} />
                            ) : (
                              <Avatar>
                                {programInfo.lastEdited.firstname
                                  .charAt(0)
                                  .toUpperCase()}
                              </Avatar>
                            )}
                            <Typography>
                              {programInfo.lastEdited.firstname +
                                " " +
                                programInfo.lastEdited.lastname}
                            </Typography>
                          </div>
                        )}
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid container className={classes.programInfo}>
                  <Grid item xs={6} md={4} className={classes.boldText}>
                    Date Added:
                  </Grid>
                  <Grid item xs={6} md={8}>
                    <Grid container>
                      <Grid item xs={6}>
                        {programInfo.created &&
                          new Date(programInfo.created).toLocaleDateString(
                            "en-US",
                            {
                              month: "short",
                              day: "numeric",
                              year: "numeric",
                            }
                          )}
                      </Grid>
                      <Grid item xs={6}>
                        {programInfo.createdBy && (
                          <div className={classes.userInfo}>
                            {programInfo.createdBy.photoUrl ? (
                              <Avatar src={programInfo.createdBy.photoUrl} />
                            ) : (
                              <Avatar>
                                {programInfo.createdBy.firstname
                                  .charAt(0)
                                  .toUpperCase()}
                              </Avatar>
                            )}
                            <Typography>
                              {programInfo.createdBy.firstname +
                                " " +
                                programInfo.createdBy.lastname}
                            </Typography>
                          </div>
                        )}
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Grid container>
          <Grid item xs={12} className={classes.headingContainer}>
            <div className={classes.flex}>
              <Typography className={classes.boldText}>
                Selected Skills
              </Typography>
              <Typography className={classes.skillsText}>
                {selectedSkills.length} skills
              </Typography>
            </div>
            <div className={classes.flex}>
              <Typography className={classes.hardSkillsText}>
                <CircleIcon fontSize="small" />
                Hard Skills
              </Typography>
              <Typography className={classes.softSkillsText}>
                <CircleIcon fontSize="small" />
                Soft Skills
              </Typography>
            </div>
          </Grid>
        </Grid>
        <Grid container className={classes.skillsContainer}>
          <Grid item xs={12}>
            {selectedSkills.map((skill, index) => (
              <Chip
                key={index}
                label={skill.name}
                className={
                  skill.type === "hard"
                    ? classes.hardSkillChip
                    : classes.softSkillChip
                }
              />
            ))}
          </Grid>
        </Grid>
      </Grid>
    </CustomGrid>
  );
};
