import { alpha, darken, styled, Grid } from "@mui/material";
import { FixMeLater } from "../../../any";

const PREFIX = "AddSkills-Page";

export const classes = {
  headingContainer: `${PREFIX}-headingContainer`,
  heading: `${PREFIX}-heading`,
  skillsText: `${PREFIX}-skillsText`,
  flex: `${PREFIX}-flex`,
  skillsContainer: `${PREFIX}-skillsContainer`,
  hardSkillsText: `${PREFIX}-hardSkillsText`,
  softSkillsText: `${PREFIX}-softSkillsText`,
  hardSkillChip: `${PREFIX}-hardSkillChip`,
  softSkillChip: `${PREFIX}-softSkillChip`,
};

export const PageCustomGrid = styled(Grid)(({ theme }: FixMeLater) => ({
  minHeight: "70vh",
  marginTop: "5px",
  backgroundColor: "#EFEFEF",
  borderRadius: "4px",
  boxShadow:
    "0px 3px 1px -2px rgba(0,0,0,0.2), 0px 2px 2px 0px rgba(0,0,0,0.14), 0px 1px 5px 0px rgba(0,0,0,0.12)",
}));

export const CustomGrid = styled(Grid)(({ theme }: FixMeLater) => ({
  padding: "10px",
  borderRadius: "4px",
  backgroundColor: "#F3F3F3",
  boxShadow:
    "0px 3px 1px -2px rgba(0,0,0,0.2), 0px 2px 2px 0px rgba(0,0,0,0.14), 0px 1px 5px 0px rgba(0,0,0,0.12)",
  "@media (max-width: 1200px)": {
    marginTop: "20px",
  },
  [`& .${classes.headingContainer}`]: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    marginBottom: "10px",
  },
  [`& .${classes.heading}`]: {
    fontWeight: 600,
  },
  [`& .${classes.skillsText}`]: {
    position: "relative",
    top: "1px",
    fontSize: "14px",
    fontStyle: "italic",
    color: theme.palette.grey.main,
    marginLeft: "10px",
  },
  [`& .${classes.flex}`]: {
    display: "flex",
  },
  [`& .${classes.skillsContainer}`]: {
    maxHeight: "95%",
    overflowY: "scroll",
    "@media (max-width: 1200px)": {
      maxHeight: "92%",
    },
  },
  [`& .${classes.hardSkillsText}`]: {
    display: "flex",
    fontSize: "14px",
    color: theme.palette.skillMapping.secondary,
  },
  [`& .${classes.softSkillsText}`]: {
    display: "flex",
    fontSize: "14px",
    color: theme.palette.skillMapping.main,
    marginLeft: "10px",
  },
  [`& .${classes.hardSkillChip}`]: {
    margin: "5px",
    borderRadius: "6px",
    backgroundColor: alpha(theme.palette.skillMapping.secondary, 0.4),
    color: darken(theme.palette.skillMapping.secondary, 0.4),
    cursor: "pointer",
    boxShadow:
      "0px 3px 1px -2px rgba(0,0,0,0.2), 0px 2px 2px 0px rgba(0,0,0,0.14), 0px 1px 5px 0px rgba(0,0,0,0.12)",
    "&:hover": {
      backgroundColor: alpha(theme.palette.skillMapping.secondary, 0.4),
    },
  },
  [`& .${classes.softSkillChip}`]: {
    margin: "5px",
    borderRadius: "6px",
    backgroundColor: alpha(theme.palette.skillMapping.main, 0.4),
    color: darken(theme.palette.skillMapping.main, 0.4),
    cursor: "pointer",
    boxShadow:
      "0px 3px 1px -2px rgba(0,0,0,0.2), 0px 2px 2px 0px rgba(0,0,0,0.14), 0px 1px 5px 0px rgba(0,0,0,0.12)",
    "&:hover": {
      backgroundColor: alpha(theme.palette.skillMapping.main, 0.4),
    },
  },
}));
