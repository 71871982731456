import { styled } from "@mui/material";
import FormControl from "@mui/material/FormControl";

const PREFIX = "Dropdown";

export const classes = {
  formControl: `${PREFIX}-formControl`,
  align_left: `${PREFIX}-align_left`,
  align_right: `${PREFIX}-align_right`,
  align_center: `${PREFIX}-align_center`,
};

export const Item = styled(FormControl)(({ theme }) => ({
  [`&.${classes.formControl}`]: {
    minWidth: "100%",
    maxWidth: "inherit",
  },
  [`& .${classes.align_left}`]: {
    textAlign: "left",
  },
  [`& .${classes.align_right}`]: { textAlign: "right" },
  [`& .${classes.align_center}`]: { textAlign: "center" },
}));
