import { useState } from "react";

import {
  Grid,
  TextField,
  MenuItem,
  Button,
  Typography,
  Modal,
  Alert,
} from "@mui/material";

import { useLazyQuery } from "@apollo/client";
import { PHOTON_GRAPH_URL } from "../../../environmentConfig";
import { FixMeLater } from "../../../any";

import { credentialTypes, CHECK_PROGRAM } from "./helper";
import { CustomBox, classes } from "./style";
import { useEffect } from "react";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 550,
  bgcolor: "background.paper",
  borderRadius: "8px",
  boxShadow: 24,
  padding: "30px 100px",
  "@media (max-width: 900px)": {
    width: 400,
    padding: "30px 40px",
  },
};

interface ModalProps {
  open: boolean;
  handleClose: () => void;
  handleAddProgram: (
    programName: string,
    field: string,
    type: string,
    duration: string
  ) => void;
  institution: string;
}

export const AddProgramModal = ({
  open,
  handleClose,
  handleAddProgram,
  institution,
}: ModalProps) => {
  const [faculty, setFaculty] = useState("");
  const [field, setField] = useState("");
  const [type, setType] = useState("");
  const [programName, setProgramName] = useState("");
  const [duration, setDuration] = useState("");
  const [programExists, setProgramExists] = useState(false);

  const [checkProgramExists] = useLazyQuery(CHECK_PROGRAM, {
    context: { uri: PHOTON_GRAPH_URL },
  });

  useEffect(() => {
    if (programName && field) {
      checkProgramExists({
        variables: {
          name: programName,
          field,
          institution,
        },
        onCompleted: (data: FixMeLater) => {
          if (data.programs.nodes.length > 0) {
            setProgramExists(true);
          } else {
            setProgramExists(false);
          }
        },
      });
    }
  }, [programName, field]);

  // Autopopulate program name if the user selects Bachelor's, Master's, or Doctoral degree
  // These program names will be in the format "Bachelor of Science", however the user may still change the name if they choose
  useEffect(() => {
    if (
      type &&
      faculty &&
      ["Bachelor's", "Master's", "Doctoral"].includes(type) &&
      programName !== type.split("'")[0] + " of " + faculty
    ) {
      setProgramName(type.split("'")[0] + " of " + faculty);
    } else if (
      type &&
      !faculty &&
      ["Bachelor's", "Master's", "Doctoral"].includes(type) &&
      programName === type.split("'")[0] + " of " + faculty.slice(0, 1)
    ) {
      setProgramName("");
    }
  }, [type, faculty]);

  const handleCancel = () => {
    setFaculty("");
    setField("");
    setType("");
    setProgramName("");
    setDuration("");
    setProgramExists(false);

    handleClose();
  };

  const handleAdd = (
    programName: string,
    field: string,
    type: string,
    duration: string
  ) => {
    setFaculty("");
    setField("");
    setType("");
    setProgramName("");
    setDuration("");
    setProgramExists(false);

    handleAddProgram(programName, field, type, duration);
  };

  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <CustomBox sx={style}>
        <Typography variant="h5" className={classes.title}>
          Add Program
        </Typography>
        <Grid container className={classes.container}>
          <Grid item xs={12}>
            <Grid container className={classes.programInfo}>
              <Grid item xs={6} md={4} className={classes.boldText}>
                Institution:
              </Grid>
              <Grid item xs={6} md={8}>
                {institution}
              </Grid>
            </Grid>
            <Grid container className={classes.programInfo}>
              <Grid item xs={6} md={4} className={classes.boldText}>
                Credential Type:
              </Grid>
              <Grid item xs={6} md={8}>
                <TextField
                  label="Credential Type"
                  variant="outlined"
                  color="skillMapping"
                  value={type}
                  onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                    setType(event.target.value);
                  }}
                  select
                  fullWidth
                >
                  {credentialTypes.map((option: string, index: number) => (
                    <MenuItem key={index} value={option}>
                      {option}
                    </MenuItem>
                  ))}
                </TextField>
              </Grid>
            </Grid>
            {/* Only fill out faculty if the user selects Bachlor's, Master's, or Doctoral */}
            {["Bachelor's", "Master's", "Doctoral"].includes(type) && (
              <Grid container className={classes.programInfo}>
                <Grid item xs={6} md={4} className={classes.boldText}>
                  Faculty:
                </Grid>
                <Grid item xs={6} md={8}>
                  <TextField
                    label="Faculty"
                    placeholder="E.g. Science, Kinesology, Arts"
                    variant="outlined"
                    color="skillMapping"
                    value={faculty}
                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                      setFaculty(event.target.value);
                    }}
                    fullWidth
                  />
                </Grid>
              </Grid>
            )}
            <Grid container className={classes.programInfo}>
              <Grid item xs={6} md={4} className={classes.boldText}>
                Program Name:
              </Grid>
              <Grid item xs={6} md={8}>
                <TextField
                  label="Program Name"
                  placeholder="E.g. Open Studies, Post Masters Diploma"
                  variant="outlined"
                  color="skillMapping"
                  value={programName}
                  onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                    setProgramName(event.target.value);
                  }}
                  fullWidth
                  disabled={!type}
                />
              </Grid>
            </Grid>
            <Grid container className={classes.programInfo}>
              <Grid item xs={6} md={4} className={classes.boldText}>
                Minimum Duration (Optional):
              </Grid>
              <Grid item xs={6} md={8}>
                <TextField
                  label="Minimum Duration"
                  variant="outlined"
                  color="skillMapping"
                  value={duration}
                  onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                    setDuration(event.target.value);
                  }}
                  select
                  fullWidth
                  disabled={!type}
                >
                  {Array.from({ length: 6 }, (_, i) => i + 1).map(
                    (option: number, index: number) => (
                      <MenuItem key={index} value={option}>
                        {option} {option === 1 ? " year" : " years"}
                      </MenuItem>
                    )
                  )}
                </TextField>
              </Grid>
            </Grid>
            <Grid container className={classes.programInfo}>
              <Grid item xs={6} md={4} className={classes.boldText}>
                Field:
              </Grid>
              <Grid item xs={6} md={8}>
                <TextField
                  label="Field"
                  placeholder="E.g. Chemistry, Accounting, Dance"
                  variant="outlined"
                  color="skillMapping"
                  value={field}
                  onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                    setField(event.target.value);
                  }}
                  fullWidth
                  disabled={!type}
                />
              </Grid>
            </Grid>
            {programExists && (
              <Grid container>
                <Grid item xs={12}>
                  <Alert severity="info">
                    Please note: There's already a program with the name '
                    {programName}' in the field {field}.
                  </Alert>
                </Grid>
              </Grid>
            )}
          </Grid>
        </Grid>
        <Grid container>
          <Grid item xs={12} className={classes.buttonsContainer}>
            <Button
              className={classes.button}
              color="red"
              variant="contained"
              size="small"
              onClick={handleCancel}
            >
              Cancel
            </Button>
            <Button
              className={classes.button}
              color="skillMapping"
              variant="contained"
              size="small"
              disabled={
                !programName ||
                !field ||
                !type ||
                (!faculty &&
                  ["Bachelor's", "Master's", "Doctoral's"].includes(type))
              }
              onClick={() => handleAdd(programName, field, type, duration)}
            >
              Add Program
            </Button>
          </Grid>
        </Grid>
      </CustomBox>
    </Modal>
  );
};
