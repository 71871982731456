import {
  ApolloQueryResult,
  gql,
  OperationVariables,
  useMutation,
} from "@apollo/client";
import { useNavigate } from "react-router-dom";

import {
  Grid,
  Typography,
  Avatar,
  Divider,
  Button,
  MenuItem,
} from "@mui/material";
import ArrowRightIcon from "@mui/icons-material/ArrowCircleRightOutlined";

import { ProgramsType } from "../dataGrid/dataRows";
import { SkillTags } from "./skillTags";
import { EditFields } from "./editFields";
import { CustomBox, classes } from "./style";
import { credentialTypes } from "../addProgram/helper";
import { DeleteProgram } from "./deleteProgram";

import { PHOTON_GRAPH_URL } from "../../../environmentConfig";
import useToken from "../../../useToken";

interface ProgramInformationProps {
  selectedProgram: ProgramsType;
  setDeletedProgram: any;
  setOpenSnackbar: any;
  setSnackbarMsg: any;
  refetchPrograms: (
    variables?: Partial<OperationVariables> | undefined
  ) => Promise<ApolloQueryResult<any>>;
}

const UPDATE_PROGRAM = gql`
  mutation UpdateProgram(
    $duration: Int
    $field: String!
    $name: String!
    $programId: Int!
    $type: String!
    $userId: String!
  ) {
    updateProgram(
      input: {
        _duration: $duration
        _editingUserId: $userId
        _field: $field
        _name: $name
        _programId: $programId
        _type: $type
      }
    ) {
      boolean
    }
  }
`;

export const ProgramInformation = ({
  selectedProgram,
  refetchPrograms,
  setDeletedProgram,
  setOpenSnackbar,
  setSnackbarMsg,
}: ProgramInformationProps): JSX.Element => {
  const { token } = useToken();

  const [updateProgram] = useMutation(UPDATE_PROGRAM, {
    context: { uri: PHOTON_GRAPH_URL },
  });

  const updateField = async (
    value: string | number,
    key: string
  ): Promise<void> => {
    const programEdits: ProgramsType = {
      ...selectedProgram,
      [key]: value,
    };

    const update = await updateProgram({
      variables: {
        duration: programEdits.duration || null,
        field: programEdits.field,
        name: programEdits.name,
        programId: programEdits.programId,
        type: programEdits.type,
        userId: token.uid,
      },
    });
    if (update) {
      refetchPrograms();
    }
  };

  const navigate = useNavigate();

  const handleAddSkills = () => {
    navigate(`/add-skills/${selectedProgram.programId}/occupations`);
  };

  const cleanDuration = (duration: string) => {
    return duration ? duration + " years" : "N/A";
  };

  return (
    <CustomBox>
      <Grid container spacing={2} className={classes.container}>
        <Grid item xs={12}>
          <EditFields
            fullWidth
            update={updateField}
            value={selectedProgram.name}
            valueKey={"name"}
          />
        </Grid>
        <Grid item xs={12}>
          <Divider className={classes.divider} />
        </Grid>
        <Grid item xs={12}>
          <Grid container className={classes.programInfo}>
            <Grid item xs={6} md={4} className={classes.boldText}>
              Field:
            </Grid>
            <EditFields
              update={updateField}
              value={selectedProgram.field}
              valueKey={"field"}
            />
          </Grid>
          <Grid container className={classes.programInfo}>
            <Grid item xs={6} md={4} className={classes.boldText}>
              Type:
            </Grid>
            <EditFields
              options={credentialTypes.map((option: string, index: number) => (
                <MenuItem key={index} value={option}>
                  {option}
                </MenuItem>
              ))}
              update={updateField}
              value={selectedProgram.type}
              valueKey={"type"}
            />
          </Grid>
          <Grid container className={classes.programInfo}>
            <Grid item xs={6} md={4} className={classes.boldText}>
              Minimum Duration:
            </Grid>
            <EditFields
              displayFunction={cleanDuration}
              options={Array.from({ length: 6 }, (_, i) => i + 1)
                .map((option: number, index: number) => (
                  <MenuItem key={index} value={option}>
                    {option} {option === 1 ? " year" : " years"}
                  </MenuItem>
                ))
                .concat(
                  <MenuItem key={"empty"} value="">
                    <em>N/A</em>
                  </MenuItem>
                )}
              update={updateField}
              value={selectedProgram.duration || ""}
              valueKey={"duration"}
            />
          </Grid>
          <Grid container className={classes.programInfo}>
            <Grid item xs={6} md={4} className={classes.boldText}>
              Last Edited:
            </Grid>
            <Grid item xs={6} md={8}>
              <Grid container>
                <Grid item xs={6}>
                  {selectedProgram.edited
                    ? new Date(selectedProgram.edited).toLocaleDateString(
                        "en-US",
                        {
                          month: "short",
                          day: "numeric",
                          year: "numeric",
                        }
                      )
                    : "N/A"}
                </Grid>
                <Grid item xs={6}>
                  {selectedProgram.edited && selectedProgram.lastEdited && (
                    <div className={classes.userInfo}>
                      {selectedProgram.lastEdited.photoUrl ? (
                        <Avatar src={selectedProgram.lastEdited.photoUrl} />
                      ) : (
                        <Avatar>
                          {selectedProgram.lastEdited.firstname
                            .charAt(0)
                            .toUpperCase()}
                        </Avatar>
                      )}
                      <Typography>
                        {(selectedProgram.lastEdited.firstname || "") +
                          " " +
                          (selectedProgram.lastEdited.lastname || "")}
                      </Typography>
                    </div>
                  )}
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <Grid container className={classes.programInfo}>
            <Grid item xs={6} md={4} className={classes.boldText}>
              Date Added:
            </Grid>
            <Grid item xs={6} md={8}>
              <Grid container>
                <Grid item xs={6}>
                  {selectedProgram.created &&
                    new Date(selectedProgram.created).toLocaleDateString(
                      "en-US",
                      {
                        month: "short",
                        day: "numeric",
                        year: "numeric",
                      }
                    )}
                </Grid>
                <Grid item xs={6}>
                  {selectedProgram.createdBy && (
                    <div className={classes.userInfo}>
                      {selectedProgram.createdBy.photoUrl ? (
                        <Avatar src={selectedProgram.createdBy.photoUrl} />
                      ) : (
                        <Avatar>
                          {selectedProgram.createdBy.firstname
                            .charAt(0)
                            .toUpperCase()}
                        </Avatar>
                      )}
                      <Typography>
                        {(selectedProgram.createdBy.firstname || "") +
                          " " +
                          (selectedProgram.createdBy.lastname || "")}
                      </Typography>
                    </div>
                  )}
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <Divider className={classes.divider} />
        </Grid>
        <Grid item xs={12} className={classes.skillInfo}>
          <div className={classes.skillText}>
            <Typography variant="h6">Skills</Typography>
            <Typography variant="h6" className={classes.skillNumber}>
              {(selectedProgram.hardSkills?.length || 0) +
                (selectedProgram.softSkills?.length || 0)}{" "}
              skills selected
            </Typography>
          </div>
          <Button
            color="skillMapping"
            variant="contained"
            size="small"
            className={classes.editSkills}
            endIcon={<ArrowRightIcon />}
            onClick={handleAddSkills}
          >
            {(selectedProgram.hardSkills?.length || 0) +
              (selectedProgram.softSkills?.length || 0) >
            0
              ? "Edit Skills"
              : "Add Skills"}
          </Button>
        </Grid>
        <SkillTags
          hardSkills={selectedProgram.hardSkills.filter(
            (skill) => skill.guaranteed
          )}
          softSkills={selectedProgram.softSkills.filter(
            (skill) => skill.guaranteed
          )}
          skillCategory="Guaranteed"
        />
        <SkillTags
          hardSkills={selectedProgram.hardSkills.filter(
            (skill) => !skill.guaranteed
          )}
          softSkills={selectedProgram.softSkills.filter(
            (skill) => !skill.guaranteed
          )}
          skillCategory="Optional"
        />
      </Grid>
      <Grid container>
        <Grid item xs={12} className={classes.deleteButton}>
          <DeleteProgram
            selectedProgram={{ ...selectedProgram }}
            setDeletedProgram={setDeletedProgram}
            setOpenSnackbar={setOpenSnackbar}
            setSnackbarMsg={setSnackbarMsg}
          />
        </Grid>
      </Grid>
    </CustomBox>
  );
};
