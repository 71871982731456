import { Grid, Typography, Chip } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import CircleIcon from "@mui/icons-material/FiberManualRecord";

import { SkillType } from "../helper";
import { CustomGrid, classes } from "./style";

export interface SelectedSkillsProps {
  selectedSkills: SkillType[];
  handleSelectSkill: (skill: SkillType) => void;
}

export const SelectedSkills = ({
  selectedSkills,
  handleSelectSkill,
}: SelectedSkillsProps) => {
  return (
    <CustomGrid container>
      <Grid item xs={12} sx={{ height: "70vh" }}>
        <Grid container>
          <Grid item xs={12} className={classes.headingContainer}>
            <div className={classes.flex}>
              <Typography className={classes.heading}>
                Selected Skills
              </Typography>
              <Typography className={classes.skillsText}>
                {selectedSkills.length} skills
              </Typography>
            </div>
            <div className={classes.flex}>
              <Typography className={classes.hardSkillsText}>
                <CircleIcon fontSize="small" />
                Hard Skills
              </Typography>
              <Typography className={classes.softSkillsText}>
                <CircleIcon fontSize="small" />
                Soft Skills
              </Typography>
            </div>
          </Grid>
        </Grid>
        <Grid container className={classes.skillsContainer}>
          <Grid item xs={12}>
            {selectedSkills.map((skill, index) => (
              <Chip
                key={index}
                label={skill.name}
                className={
                  skill.type === "hard"
                    ? classes.hardSkillChip
                    : classes.softSkillChip
                }
                deleteIcon={<CloseIcon fontSize="small" />}
                onClick={() => handleSelectSkill(skill)}
                onDelete={() => handleSelectSkill(skill)}
              />
            ))}
          </Grid>
        </Grid>
      </Grid>
    </CustomGrid>
  );
};
