import { alpha, darken, styled, Box, Grid } from "@mui/material";
import { FixMeLater } from "../../../any";

const PREFIX = "SidePanel";

export const classes = {
  container: `${PREFIX}-container`,
  divider: `${PREFIX}-divider`,
  programInfo: `${PREFIX}-programInfo`,
  skillInfo: `${PREFIX}-skillInfo`,
  skillText: `${PREFIX}-skillText`,
  skillNumber: `${PREFIX}-skillNumber`,
  boldText: `${PREFIX}-boldText`,
  editSkills: `${PREFIX}-editSkills`,
  deleteButton: `${PREFIX}-deleteButton`,
  userInfo: `${PREFIX}-userInfo`,
  header: `${PREFIX}-header`,
  flex: `${PREFIX}-flex`,
  skillsContainer: `${PREFIX}-skillsContainer`,
  emptyContainer: `${PREFIX}-emptyContainer`,
  hardSkillsText: `${PREFIX}-hardSkillsText`,
  softSkillsText: `${PREFIX}-softSkillsText`,
  noSkillsText: `${PREFIX}-noSkillsText`,
  hardSkillChip: `${PREFIX}-hardSkillChip`,
  softSkillChip: `${PREFIX}-softSkillChip`,
};

export const CustomBox = styled(Box)(({ theme }: FixMeLater) => ({
  width: "500px",
  margin: "20px",
  [`& .${classes.container}`]: {
    display: "inline-block",
    minHeight: "calc(100vh - 75px)",
  },
  [`& .${classes.divider}`]: {
    marginTop: "15px",
  },
  [`& .${classes.programInfo}`]: {
    margin: "10px 0px 30px",
  },
  [`& .${classes.skillInfo}`]: {
    display: "flex",
    justifyContent: "space-between",
  },
  [`& .${classes.skillText}`]: {
    display: "flex",
    alignItems: "center",
  },
  [`& .${classes.skillNumber}`]: {
    marginLeft: "15px",
    fontStyle: "italic",
    fontSize: "16px",
    color: theme.palette.grey.main,
  },
  [`& .${classes.boldText}`]: {
    alignItems: 'center',
    display: 'flex',
    fontWeight: 600,
  },
  [`& .${classes.editSkills}`]: {
    borderRadius: "16px",
    padding: "4px 12px",
    "&:hover": {
      cursor: "pointer",
    },
  },
  [`& .${classes.deleteButton}`]: {
    marginTop: "20px",
    display: "flex",
    justifyContent: "end",
  },
  [`& .${classes.userInfo}`]: {
    display: "flex",
    alignItems: "center",
    position: "relative",
    bottom: "5px",
    "& .MuiAvatar-root": {
      marginRight: "10px",
      fontSize: "14px",
      height: "30px",
      width: "30px",
    },
  },
}));

export const CustomGrid = styled(Grid)(({ theme }: FixMeLater) => ({
  display: "flex",
  justifyContent: "space-between",
  marginTop: "10px",
  [`& .${classes.header}`]: {
    display: "flex",
    justifyContent: "space-between",
  },
  [`& .${classes.flex}`]: {
    display: "flex",
  },
  [`& .${classes.skillsContainer}`]: {
    maxHeight: "50vh",
    overflowY: "scroll",
    border: "1px solid",
    borderColor: theme.palette.grey.light,
    borderRadius: "6px",
  },
  [`& .${classes.emptyContainer}`]: {
    maxHeight: "50vh",
    overflowY: "scroll",
  },
  [`& .${classes.hardSkillsText}`]: {
    display: "flex",
    color: theme.palette.skillMapping.secondary,
  },
  [`& .${classes.softSkillsText}`]: {
    display: "flex",
    color: theme.palette.skillMapping.main,
    marginLeft: "10px",
  },
  [`& .${classes.noSkillsText}`]: {
    marginTop: "10px",
    color: theme.palette.grey.medium,
    fontStyle: "italic",
  },
  [`& .${classes.hardSkillChip}`]: {
    margin: "5px",
    borderRadius: "6px",
    backgroundColor: alpha(theme.palette.skillMapping.secondary, 0.4),
    color: darken(theme.palette.skillMapping.secondary, 0.4),
  },
  [`& .${classes.softSkillChip}`]: {
    margin: "5px",
    borderRadius: "6px",
    backgroundColor: alpha(theme.palette.skillMapping.main, 0.4),
    color: darken(theme.palette.skillMapping.main, 0.4),
  },
}));
