import { styled, Grid } from "@mui/material";
import { FixMeLater } from "../../any";

const PREFIX = "AddSkillsPage";

export const classes = {
  headerText: `${PREFIX}-headerText`,
  headerDivider: `${PREFIX}-headerDivider`,
  subheaderText: `${PREFIX}-subheaderText`,
  button: `${PREFIX}-button`,
  buttonIcon: `${PREFIX}-buttonIcon`,
  skipButton: `${PREFIX}-skipButton`,
  stepper: `${PREFIX}-stepper`,
  pageTitle: `${PREFIX}-pageTitle`,
  pageBody: `${PREFIX}-pageBody`,
  pageButtons: `${PREFIX}-pageButtons`,
  tutorialContainer: `${PREFIX}-tutorialContainer`,
  tutorialText: `${PREFIX}-tutorialText`,
  tutorialIcon: `${PREFIX}-tutorialIcon`,
  tutorialButton: `${PREFIX}-tutorialButton`,
};

export const Root = styled("div")(({ theme }: FixMeLater) => ({
  marginTop: "50px",
  maxHeight: "90vh",
  padding: "0px 20px",
  [`& .${classes.headerText}`]: {
    display: "flex",
  },
  [`& .${classes.headerDivider}`]: {
    margin: "5px 20px",
    border: "1.5px solid black",
  },
  [`& .${classes.subheaderText}`]: {
    color: theme.palette.grey.main,
  },
  [`& .${classes.button}`]: {
    borderRadius: "16px",
    padding: "4px 16px",
    "&:hover": {
      cursor: "pointer",
    },
  },
  [`& .${classes.buttonIcon}`]: {
    position: "relative",
    bottom: "1px",
  },
  [`& .${classes.skipButton}`]: {
    borderRadius: "16px",
    padding: "4px 16px",
    textDecoration: "underline",
    "&:hover": {
      cursor: "pointer",
      textDecoration: "underline",
    },
  },
  [`& .${classes.stepper}`]: {
    margin: "50px 25px 0px 0px",
    cursor: "pointer",
    "@media (max-width: 600px)": {
      margin: "20px 0px",
    },
    "& .MuiStepConnector-line": {
      borderLeftWidth: "2px",
    },
    "& .Mui-active": {
      "&.MuiStepLabel-label": {
        fontWeight: 600,
      },
      "&.MuiStepIcon-root": {
        color: theme.palette.skillMapping.secondary,
      },
      "& .MuiStepConnector-line": {
        borderColor: theme.palette.skillMapping.secondary,
      },
    },
    "& .Mui-completed": {
      "&.MuiStepIcon-root": {
        color: theme.palette.skillMapping.secondary,
      },
      "& .MuiStepConnector-line": {
        borderColor: theme.palette.skillMapping.secondary,
      },
    },
    "& .MuiTypography-root": {
      color: theme.palette.grey.main,
    },
  },
  [`& .${classes.tutorialContainer}`]: {
    margin: '0',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100%',
  },
  [`& .${classes.tutorialText}`]: {
    margin: '0',
    padding: '0 5px',
    color: theme.palette.grey.main,
  },
  [`& .${classes.tutorialIcon}`]: {
    color: theme.palette.skillMapping.secondary,
  },
  [`& .${classes.tutorialButton}`]: {
    float: 'right',
    border: 'none',
    backgroundColor: 'white',
  },
}));

export const PageContentGrid = styled(Grid)(({ theme }: FixMeLater) => ({
  marginTop: "25px",
  [`& .${classes.pageTitle}`]: {
    padding: "10px",
    borderRadius: "4px",
    backgroundColor: theme.palette.skillMapping.main,
    color: theme.palette.skillMapping.contrastText,
    fontWeight: 600,
    boxShadow:
      "0px 3px 1px -2px rgba(0,0,0,0.2), 0px 2px 2px 0px rgba(0,0,0,0.14), 0px 1px 5px 0px rgba(0,0,0,0.12)",
  },
  [`& .${classes.pageBody}`]: {
    minHeight: "70vh",
    marginTop: "10px",
  },
  [`& .${classes.pageButtons}`]: {
    margin: "20px 0px",
    display: "flex",
    justifyContent: "flex-end",
  },
}));
