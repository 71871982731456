import { Grid, Typography, Chip } from "@mui/material";
import CircleIcon from "@mui/icons-material/FiberManualRecord";

import { FixMeLater } from "../../../any";
import { CustomGrid, classes } from "./style";

interface SkillTagsProps {
  hardSkills: FixMeLater[];
  softSkills: FixMeLater[];
  skillCategory: string;
}

export const SkillTags = ({
  hardSkills,
  softSkills,
  skillCategory,
}: SkillTagsProps) => {
  return (
    <CustomGrid item xs={12}>
      <Grid container>
        <Grid item xs={12} className={classes.header}>
          <Typography className={classes.boldText}>
            {skillCategory} Skills
          </Typography>
          <div className={classes.flex}>
            <Typography className={classes.hardSkillsText}>
              <CircleIcon />
              Hard Skills
            </Typography>
            <Typography className={classes.softSkillsText}>
              <CircleIcon />
              Soft Skills
            </Typography>
          </div>
        </Grid>
        <Grid
          item
          xs={12}
          className={
            (hardSkills?.length || 0) + (softSkills?.length || 0) > 0
              ? classes.skillsContainer
              : classes.emptyContainer
          }
        >
          {/* TODO: All skills will show up as guarateed currently. This will change once we implement the organizing skills functionality */}
          {(hardSkills?.length || 0) + (softSkills?.length || 0) > 0 ? (
            <div>
              {hardSkills.map((item, index) => (
                <Chip
                  key={index}
                  label={item.skill.name}
                  className={classes.hardSkillChip}
                />
              ))}
              {softSkills.map((item, index) => (
                <Chip
                  key={index}
                  label={item.skill.name}
                  className={classes.softSkillChip}
                />
              ))}
            </div>
          ) : (
            <Typography className={classes.noSkillsText}>
              No {skillCategory.toLowerCase()} skills selected.
            </Typography>
          )}
        </Grid>
      </Grid>
    </CustomGrid>
  );
};
