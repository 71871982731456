import React, { useState, useEffect } from 'react';

import FormControl from '@mui/material/FormControl';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import IconButton from '@mui/material/IconButton';
import Paper from '@mui/material/Paper';
import SearchIcon from '@mui/icons-material/Search';
import { Autocomplete, TextField } from '@mui/material';
import { classes, MobileSearchGrid } from './style';

const DropdownSearchMobile = ({
  toggleSearchDrawer,
  options,
  onSelect,
  selectedValue,
  dataProvider,
  defaultOption='',
  label,
  id,
  app,
  dropdownStyles,
  group
}) => {

  const [inputValue, setInputValue] = useState(defaultOption);

  useEffect(() => {
    const selection = options.find(option => option.value === selectedValue);
    setInputValue(selection?.label || defaultOption);
  }, [selectedValue, defaultOption, options])

  const filteredOptions =
  inputValue === defaultOption
    ? options
    : options.filter((elem) =>
        elem.label.toLowerCase().includes(inputValue.toLowerCase())
      );



  return (
    <MobileSearchGrid container>
      <Grid item xs={12} className={classes.header}>
        <Grid container className={classes.searchBar}>
          <Grid item xs={2} sm={1} className={classes.backButton}>
            <IconButton
              color={app}
              aria-label="Back"
              onClick={() => toggleSearchDrawer(false)}
              sx={{ width: '100%' }}
            >
              <ArrowBackIcon />
            </IconButton>
          </Grid>
          <Grid item xs={10} sm={11}>
            <FormControl fullWidth>
              <Autocomplete
                freeSolo
                disablePortal
                forcePopupIcon={false}
                open
                id={id}
                inputValue={inputValue}
                color={app}
                sx={dropdownStyles}
                options={dataProvider}
                renderInput={(params) => (
                  <TextField 
                    {...params} 
                    label={label} 
                  />
                )}
                PaperComponent={props => (
                  <Paper
                    sx={{
                      display: 'none',
                    }}
                    {...props}
                  />
                )}
                onInputChange={(event, newInputValue) => {
                  // Only set input value when user types
                  if (event !== null && event.type !== 'blur') {
                    setInputValue(newInputValue);
                  }
                }}
              />
            </FormControl>
          </Grid>
        </Grid>
        <Divider />
      </Grid>
      <Grid item xs={12}>
        {filteredOptions.map((elem, idx, arr) => {
          return (
            <>
            {group && idx > 0 && elem[group] !== arr[idx-1][group] && 
              <Box
                key={elem[group]}
                className={classes.groupHeader}
              >
                {elem[group]}
              </Box> 
            }
            <Box
            key={elem.value}
            className={classes.result}
            onClick={(e) => {
              onSelect(e, elem);
              toggleSearchDrawer(false);
            }}
          >
            <SearchIcon className={classes.searchIcon} />
            {elem.label}
          </Box>
          </>
          )
        })}
      </Grid>
    </MobileSearchGrid>
  );
};

export default DropdownSearchMobile;
