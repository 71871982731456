import { useState } from "react";

import { Box, Stepper, Step, StepLabel, Typography } from "@mui/material";

import { classes } from "../style";

// Label: Header text for stepper
// Description: Optional text for stepper
// Title: Title text for the page
export const steps = [
  {
    label: "Related Occupations",
    description: "Select relevant skills from a list of related occupations",
    title: "Add Skills From Related Occupations",
    index: 0,
  },
  {
    label: "Additional Skills",
    description: "Search and add additional skills to your list",
    title: "Search and Add Additional Skills",
    index: 1,
  },
  {
    label: "Organize Skills",
    description: "Sort skills into 'Guaranteed' and 'Optional' categories",
    title: "Organize Selected Skills",
    index: 2,
  },
  {
    label: "Review and Confirm",
    description: "Double-check your selections before finalizing",
    title: "Review and Confirm Selected Skills",
    index: 3,
  },
];

export interface StepProps {
  label: string;
  description: string;
  title: string;
  index: number;
}

interface StepperProps {
  pageIndex: number;
  handleSetPage: (step: StepProps) => void;
}

export const CustomStepper = ({ pageIndex, handleSetPage }: StepperProps) => {
  const handleSetStep = (step: StepProps) => {
    handleSetPage(step);
  };

  return (
    <Box sx={{ maxWidth: 400 }} id="stepper">
      <Stepper
        className={classes.stepper}
        activeStep={pageIndex}
        orientation="vertical"
      >
        {steps.map((step, index) => (
          <Step key={step.label} onClick={() => handleSetStep(step)}>
            <StepLabel
              optional={
                <Typography variant="caption">{step.description}</Typography>
              }
            >
              {step.label}
            </StepLabel>
          </Step>
        ))}
      </Stepper>
    </Box>
  );
};
