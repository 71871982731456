import { alpha, darken, styled, Grid } from "@mui/material";
import { FixMeLater } from "../../../../any";

const PREFIX = "OccupationSkillsPage";

export const classes = {
  flex: `${PREFIX}-flex`,
  leftSide: `${PREFIX}-leftSide`,
  headerContainer: `${PREFIX}-headerContainer`,
  flexGrow: `${PREFIX}-flexGrow`,
  boldText: `${PREFIX}-boldText`,
  skillsText: `${PREFIX}-skillsText`,
  description: `${PREFIX}-description`,
  skillsHeadingContainer: `${PREFIX}-skillsHeadingContainer`,
  skillsHeading: `${PREFIX}-skillsHeading`,
  button: `${PREFIX}-button`,
  skillsContainer: `${PREFIX}-skillsContainer`,
  hardSkillChip: `${PREFIX}-hardSkillChip`,
  softSkillChip: `${PREFIX}-softSkillChip`,
  unselectedChip: `${PREFIX}-unselectedChip`,
  rightSide: `${PREFIX}-rightSide`,
};

export const CustomGrid = styled(Grid)(({ theme }: FixMeLater) => ({
  height: "100%",
  [`& .${classes.flex}`]: {
    display: "flex",
  },
  [`& .${classes.leftSide}`]: {
    display: "flex",
    flexDirection: "column",
    height: "100%",
    borderRadius: "4px",
    boxShadow:
      "0px 3px 1px -2px rgba(0,0,0,0.2), 0px 2px 2px 0px rgba(0,0,0,0.14), 0px 1px 5px 0px rgba(0,0,0,0.12)",
  },
  [`& .${classes.headerContainer}`]: {
    "& .MuiAccordionSummary-content": {
      display: "flex",
      justifyContent: "space-between",
    },
  },
  [`& .${classes.flexGrow}`]: {
    flexGrow: 1,
  },
  [`& .${classes.boldText}`]: {
    fontWeight: 600,
  },
  [`& .${classes.skillsText}`]: {
    position: "relative",
    top: "1px",
    fontSize: "14px",
    fontStyle: "italic",
    color: theme.palette.grey.main,
    marginLeft: "10px",
  },
  [`& .${classes.description}`]: {
    color: theme.palette.grey.main,
    fontSize: "14px",
  },
  [`& .${classes.skillsHeadingContainer}`]: {
    display: "flex",
    margin: "10px 0px",
    alignItems: "center",
  },
  [`& .${classes.skillsHeading}`]: {
    fontWeight: 600,
  },
  [`& .${classes.button}`]: {
    marginLeft: "10px",
    fontSize: "11px",
    borderRadius: "16px",
    padding: "2px 12px",
    "&:hover": {
      cursor: "pointer",
    },
  },
  [`& .${classes.skillsContainer}`]: {
    height: "35vh",
    overflowY: "scroll",
    border: "1px solid",
    borderColor: theme.palette.grey.light,
    borderRadius: "6px",
  },
  [`& .${classes.hardSkillChip}`]: {
    margin: "5px",
    borderRadius: "6px",
    backgroundColor: alpha(theme.palette.skillMapping.secondary, 0.4),
    color: darken(theme.palette.skillMapping.secondary, 0.4),
    cursor: "pointer",
    boxShadow:
      "0px 3px 1px -2px rgba(0,0,0,0.2), 0px 2px 2px 0px rgba(0,0,0,0.14), 0px 1px 5px 0px rgba(0,0,0,0.12)",
    "&:hover": {
      backgroundColor: alpha(theme.palette.skillMapping.secondary, 0.4),
    },
  },
  [`& .${classes.softSkillChip}`]: {
    margin: "5px",
    borderRadius: "6px",
    backgroundColor: alpha(theme.palette.skillMapping.main, 0.4),
    color: darken(theme.palette.skillMapping.main, 0.4),
    cursor: "pointer",
    boxShadow:
      "0px 3px 1px -2px rgba(0,0,0,0.2), 0px 2px 2px 0px rgba(0,0,0,0.14), 0px 1px 5px 0px rgba(0,0,0,0.12)",
    "&:hover": {
      backgroundColor: alpha(theme.palette.skillMapping.main, 0.4),
    },
  },
  [`& .${classes.unselectedChip}`]: {
    margin: "5px",
    borderRadius: "6px",
    cursor: "pointer",
    "&:hover": {
      boxShadow:
        "0px 3px 1px -2px rgba(0,0,0,0.2), 0px 2px 2px 0px rgba(0,0,0,0.14), 0px 1px 5px 0px rgba(0,0,0,0.12)",
    },
  },
  [`& .${classes.rightSide}`]: {
    flexGlow: 1,
    padding: "10px",
    borderRadius: "4px",
    backgroundColor: "#F3F3F3",
    boxShadow:
      "0px 3px 1px -2px rgba(0,0,0,0.2), 0px 2px 2px 0px rgba(0,0,0,0.14), 0px 1px 5px 0px rgba(0,0,0,0.12)",
  },
}));
