import { styled } from "@mui/material";
import { FixMeLater } from "../../any";

const PREFIX = "ProgramsPage";

export const classes = {
  container: `${PREFIX}-container`,
  dataGrid: `${PREFIX}-dataGrid`,
};

export const Root = styled("div")(({ theme }: FixMeLater) => ({
  marginTop: "50px",
  [`& .${classes.container}`]: {
    width: "100%",
    minHeight: "60vh",
  },
  [`& .${classes.dataGrid}`]: {
    width: "fit-content",
    maxWidth: "100vw",
    overflowX: "scroll",
    maxHeight: "90vh",
    margin: "auto",
    padding: "0px 20px",
    border: "none",
    "& .MuiDataGrid-main": {
      width: "fit-content",
      margin: "auto",
    },
  },
}));
