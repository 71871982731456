import { gql } from "@apollo/client";

export const credentialTypes = [
  "Bachelor's",
  "Master's",
  "Doctoral",
  "Open Studies",
  "Post-Baccalaureate",
  "Post-Master's",
  "Standard",
];

export const CHECK_PROGRAM = gql`
  query CheckProgramExists(
    $name: String!
    $field: String!
    $institution: String!
  ) {
    programs(
      filter: {
        name: { equalTo: $name }
        field: { equalTo: $field }
        offered: { name: { equalTo: $institution } }
      }
    ) {
      nodes {
        id
      }
    }
  }
`;
