import { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";

import { useLazyQuery } from "@apollo/client";
import { PHOTON_GRAPH_URL } from "../../../../environmentConfig";
import { FixMeLater } from "../../../../any";

import { Grid, Typography, Chip, Stack, Skeleton, Button } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import CloseIcon from "@mui/icons-material/Close";
import { useSetState } from 'react-use';
import Joyride, { CallBackProps, STATUS, Step } from 'react-joyride';

import { SelectedSkills } from "../selectedSkills";
import { SkillType } from "../../helper";
import {
  OccupationType,
  PROGRAM_OCCUPATIONS,
  getSkills,
  Accordion,
  AccordionSummary,
  AccordionDetails,
} from "./helper";
import { CustomGrid, classes } from "./style";

interface State {
  run: boolean;
  stepList: Step[];
}

export interface OccupationSkillsPageProps {
  selectedSkills: SkillType[];
  handleSelectSkill: (skill: SkillType) => void;
  handleSelectAll: (occupation: OccupationType) => void;
  handleClearAll: (occupation: OccupationType) => void;
  tutorial: boolean;
  setTutorial: FixMeLater;
}

export const OccupationSkills = ({
  selectedSkills,
  handleSelectSkill,
  handleSelectAll,
  handleClearAll,
  tutorial,
  setTutorial
}: OccupationSkillsPageProps) => {
  const { pathname } = useLocation();
  const programId = Number(pathname.split("/")[2]);
  const [expanded, setExpanded] = useState<number>(0);
  const [occupations, setOccupations] = useState<OccupationType[]>([]);

  const [getProgramOccupations] = useLazyQuery(PROGRAM_OCCUPATIONS, {
    variables: { id: programId },
    context: { uri: PHOTON_GRAPH_URL },
  });

  useEffect(() => {
    getProgramOccupations({
      onCompleted: (data: FixMeLater) => {
        let occs;

        if (
          data.program.cipTaxonomyByCipCode.cipCrosswalksByCipCode.nodes
            .length !== 0
        ) {
          occs =
            data.program.cipTaxonomyByCipCode.cipCrosswalksByCipCode.nodes.map(
              (occupation: FixMeLater) => ({
                name: occupation.lightcast.name,
                description: occupation.lightcast.description,
                skills: getSkills(
                  occupation.lightcast.softSkillLast365Days.nodes,
                  occupation.lightcast.hardSkillLast365Days.nodes
                ),
              })
            );
        } else {
          occs = data.occupationLast365Days.nodes.map(
            (occupation: FixMeLater) => ({
              name: occupation.occupation_id.name,
              description: occupation.occupation_id.description,
              skills: getSkills(
                occupation.occupation_id.softSkillLast365Days.nodes,
                occupation.occupation_id.hardSkillLast365Days.nodes
              ),
            })
          );
        }
        setOccupations(occs);
      },
    });
  }, []);

  const [{ stepList }, setState] = useSetState<State>({
    run: tutorial,
    stepList: [
      {
        content: <div>
            <h2>Welcome to the skill selection tool!</h2>
            <p>In this form, you can define the skills that can be acquired in the selected program.</p>
          </div>,
        placement: 'center',
        target: 'body',
      },
      {
        content: <div>
            <p>
              This panel contains a list of occupations that are applicable to the selected program.
              Each occupation has a list of common skills that are typically needed. Please select any skills 
              from this list that can be acquired through the selected program.
            </p>
          </div>,
        spotlightPadding: 0,
        placement: 'right',
        target: '#occupationList',
      },
      {
        content: <div>
            <p>
              This panel contains a list of skills that you have selected. You may remove skills from 
              this panel at any time by clicking the 'x' icon on the skill's tag.
            </p>
          </div>,
        spotlightPadding: 0,
        placement: 'left',
        target: '#skillsList',
      },
      {
        content: <div>
            <p>
              Once you have completed your selections, you can continue to the next step.
            </p>
          </div>,
        spotlightPadding: 0,
        placement: 'top',
        target: '#nextButton',
      },
      {
        content: <div>
            <p>
              You can choose to move to any of the other steps of the form at using this menu. 
            </p>
          </div>,
        spotlightPadding: 5,
        placement: 'right',
        target: '#stepper',
      },
    ],
  });


  const handleJoyrideCallback = (data: CallBackProps) => {
    const { status, type } = data;
    const finishedStatuses: string[] = [STATUS.FINISHED, STATUS.SKIPPED];

    if (finishedStatuses.includes(status)) {
      setTutorial(false);
    }
  };

  const handleChange = (panel: number) => {
    setExpanded(panel);
  };

  return (
    <CustomGrid container columnSpacing={2}>
       <Joyride
        callback={handleJoyrideCallback}
        continuous
        hideCloseButton
        run={tutorial}
        scrollToFirstStep
        showProgress
        showSkipButton
        steps={stepList}
        styles={{
          options: {
            arrowColor: '#EBEBEB',
            backgroundColor: '#EBEBEB',
            primaryColor: '#76A472',
            zIndex: 10000,
          },
        }}
      />
      <Grid item xs={12} lg={6}>
        <Grid container className={classes.leftSide}>
          {occupations.length === 0 ? (
            <Stack spacing={1} sx={{ width: "100%", height: "100%" }}>
              <Skeleton variant="rounded" width="100%" height="20%" />
              <Skeleton variant="rounded" width="100%" height="20%" />
              <Skeleton variant="rounded" width="100%" height="20%" />
              <Skeleton variant="rounded" width="100%" height="20%" />
              <Skeleton variant="rounded" width="100%" height="20%" />
            </Stack>
          ) : (
            occupations.map((occupation, index) => (
              <Accordion
                key={index}
                className={expanded === index ? classes.flexGrow : ""}
                expanded={expanded === index}
                onChange={() => handleChange(index)}
              >
                <AccordionSummary
                  className={classes.headerContainer}
                  aria-controls={`panel${index}-content`}
                  id={`panel${index}-header`}
                >
                  <Typography
                    className={expanded === index ? classes.boldText : ""}
                  >
                    {occupation.name}
                  </Typography>
                  <Typography className={classes.skillsText}>
                    {
                      selectedSkills.filter((skill) => {
                        return occupation.skills.some(
                          (occupationSkill) =>
                            occupationSkill.name === skill.name
                        );
                      }).length
                    }{" "}
                    skills selected
                  </Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Typography className={classes.description}>
                    {occupation.description}
                  </Typography>
                  <Grid container>
                    <Grid
                      item
                      xs={12}
                      className={classes.skillsHeadingContainer}
                    >
                      <Typography className={classes.skillsHeading}>
                        Skills
                      </Typography>
                      <Button
                        variant="contained"
                        color="skillMapping"
                        size="small"
                        className={classes.button}
                        onClick={() => handleSelectAll(occupation)}
                      >
                        Select All
                      </Button>
                      <Button
                        variant="contained"
                        color="skillMapping"
                        size="small"
                        className={classes.button}
                        onClick={() => handleClearAll(occupation)}
                      >
                        Clear Selection
                      </Button>
                    </Grid>
                  </Grid>
                  <Grid container>
                    <Grid item xs={12} className={classes.skillsContainer}>
                      {occupation.skills.map((skill, index) =>
                        selectedSkills.some(
                          (selected) => selected.name === skill.name
                        ) ? (
                          <Chip
                            key={index}
                            label={skill.name}
                            className={
                              skill.type === "hard"
                                ? classes.hardSkillChip
                                : classes.softSkillChip
                            }
                            deleteIcon={<CloseIcon fontSize="small" />}
                            onClick={() => handleSelectSkill(skill)}
                            onDelete={() => handleSelectSkill(skill)}
                          />
                        ) : (
                          <Chip
                            key={index}
                            label={skill.name}
                            className={classes.unselectedChip}
                            deleteIcon={<AddIcon fontSize="small" />}
                            onClick={() => handleSelectSkill(skill)}
                            onDelete={() => handleSelectSkill(skill)}
                          />
                        )
                      )}
                    </Grid>
                  </Grid>
                </AccordionDetails>
              </Accordion>
            ))
          )}
        </Grid>
      </Grid>
      <Grid item xs={12} lg={6} className={classes.flex}>
        <SelectedSkills
          selectedSkills={selectedSkills}
          handleSelectSkill={handleSelectSkill}
        />
      </Grid>
    </CustomGrid>
  );
};
