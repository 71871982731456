import { Button } from "@mui/material";

import { gql, useMutation } from "@apollo/client";
import { PHOTON_GRAPH_URL } from "../../../environmentConfig";

import ConfirmDialog from "../../../components/ui/dialogs/ConfirmDialog"
import { useState } from "react";
import { ProgramsType } from "../dataGrid/dataRows";

const DELETE_PROGRAM = gql`
  mutation DeleteProgram(
    $programId: Int!
  ) {
    deleteProgram(
      input: {
        _programId: $programId
      }
    ) {
      boolean
    }
  }
`;

interface DeleteProgramProps {
  selectedProgram: ProgramsType;
  setDeletedProgram: any;
  setOpenSnackbar: any;
  setSnackbarMsg: any;
}

export const DeleteProgram = ({selectedProgram, setDeletedProgram, setOpenSnackbar, setSnackbarMsg}: DeleteProgramProps): JSX.Element => {
  const [confirmDeleteModal, setConfirmDeleteModal] = useState(false);
  const [inputValue, setInputValue] = useState<String>("");
  const [error, setError] = useState<String>("");

  const [deleteProgram] = useMutation(DELETE_PROGRAM, {
    context: { uri: PHOTON_GRAPH_URL },
  });

  const handleClick = () => {
    setConfirmDeleteModal(true);
  };

  const handleDelete = async () => {
    if(inputValue !== "DELETE") {
      setError("The entered value does not match the expected value.");
      return;
    }
    else {
      setError("");
    }
    const deletedProgram = await deleteProgram({
      variables: {
        programId: selectedProgram.programId
      },
    });
    setSnackbarMsg(`Successfully deleted ${selectedProgram?.name}`);
    setOpenSnackbar(true);
    setDeletedProgram(true);
    setConfirmDeleteModal(false);
  };

  return (
    <>
      <Button color="red" variant="contained" size="small" onClick={handleClick}>
        Delete Program
      </Button>
      <ConfirmDialog
        title="Delete Confirmation"
        body={`Are you sure you want to delete ${selectedProgram?.name}? Type DELETE to confirm.`}
        open={confirmDeleteModal}
        onClose={() => setConfirmDeleteModal(false)}
        onConfirm={handleDelete}
        setInput={setInputValue}
        error={error}
      />
    </>
  );
};
