import { styled, Grid } from "@mui/material";
import { FixMeLater } from "../../../../any";

const PREFIX = "OrganizeSkillsPage";

export const classes = {
  container: `${PREFIX}-container`,
};

export const CustomGrid = styled(Grid)(({ theme }: FixMeLater) => ({
  padding: "10px",
  borderRadius: "4px",
  backgroundColor: "#F3F3F3",
  boxShadow:
    "0px 3px 1px -2px rgba(0,0,0,0.2), 0px 2px 2px 0px rgba(0,0,0,0.14), 0px 1px 5px 0px rgba(0,0,0,0.12)",
  [`& .${classes.container}`]: {
    height: "70vh",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
  },
}));
