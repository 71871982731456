import React, { useEffect } from 'react';

import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';

import { classes, Form } from '../style';

import { gql, useLazyQuery } from '@apollo/client';

import { PHOTON_GRAPH_URL } from '../../../environmentConfig.js';
import {
  sendAccountExpiredEmail,
  sendResetPasswordEmail,
} from '../../../services/passwordResetService';
import useEmailValidation from '../../../hooks/useEmailValidation';

const isValidEmail = gql`
  query GET_USER($email: String!) {
    userAccountByEmail(email: $email) {
      password
      firstname
      email
      expired
      approved
    }
  }
`;

interface ForgotPasswordProps {
  setForm: any;
  setMsg: any;
  errorMsg: any;
  showErrorMsg: any;
}

export const ForgotPassword = ({ setForm, setMsg, errorMsg, showErrorMsg }: ForgotPasswordProps): JSX.Element => {
  const { email, setEmail, emailError, validateEmail } = useEmailValidation(
    '',
    null
  );

  const [resetPasswordQuery] = useLazyQuery(isValidEmail, {
    variables: { email: email.toLowerCase() },
    context: {
      uri: PHOTON_GRAPH_URL,
    },
  });

  useEffect(() => {
    if (email !== '') {
      validateEmail();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [email]);

  useEffect(() => {
    if (emailError === '') {
      setMsg('');
      showErrorMsg(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [emailError]);

  const handleSendResetLink = async () => {
    const mainMsg =
      'If your email exists in our database, you will receive a password reset link within the next 60 minutes.';
    if (!validateEmail()) {
      setMsg(emailError);
      showErrorMsg(true);
      return;
    }
    try {
      let result = await resetPasswordQuery({variables: { email: email }});
      if (
        result.data.userAccountByEmail &&
        result.data.userAccountByEmail.approved === true
      ) {
        const {
          expired,
          email,
          password,
          firstname,
        } = result.data.userAccountByEmail;
        if (
          expired &&
          new Date(expired).getTime() <= new Date().getTime()
        ) {
          await sendAccountExpiredEmail({ email });
          setMsg(mainMsg);
          setForm('login');
          return;
        }

        const emailResults = await sendResetPasswordEmail({
          firstname,
          email,
          password,
        });
        if (emailResults.success) {
          setForm('login');
        } else {
          setMsg(emailResults.message);
          showErrorMsg(true);
          return;
        }
      } else {
        setForm('login');
      }
      setMsg(mainMsg);
    } catch (error: any) {
      console.log(error);
      setMsg(error.message);
      showErrorMsg(true);
    }
  };

  return (
    <Form className={classes.container}>
      <TextField
        required
        label="Email"
        placeholder="Email"
        value={email}
        autoFocus={true}
        onChange={event => {
          setEmail(event.target.value);
        }}
        error={errorMsg}
      />
      <Typography variant="body2" sx={{ margin: '20px 0px', color: 'grey' }}>
        Please enter the email address associated with your account, and we'll
        send you a link to reset your password.
      </Typography>
      <Button
        variant="contained"
        onClick={handleSendResetLink}
        disabled={email === ''}
      >
        Send Reset Link
      </Button>
      <Grid
        container
        className={classes.textContainer}
        style={{ marginTop: '20px' }}
      >
        <Typography
          className={classes.linkText}
          onClick={() => {
            setForm('login');
            setMsg('');
            showErrorMsg(false);
          }}
        >
          Back to log in
        </Typography>
      </Grid>
    </Form>
  );
};
