import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import Box from '@mui/material/Box';
import Alert from '@mui/material/Alert';
import Typography from '@mui/material/Typography';

import { classes, Root } from './style';

import { LoginForm } from './login/index';
import { RegisterForm } from './register/index';
import { ForgotPassword } from './forgotPassword/index';

import useToken from '../../useToken.js';
import { gql, useLazyQuery } from '@apollo/client';
import { PHOTON_GRAPH_URL } from '../../environmentConfig.js';
import featureFlagManager, { FLAG } from '../../modules/featureFlagManager/index.jsx';

export const USER_LIST = gql`
  query GetUserList {
    userAccounts {
      edges {
        node {
          id
          email
        }
      }
    }
  }
`;

interface LoginProps {
  formType: string;
  handleClose: any;
  navigateAdminSettings: any;
}

export const Login = ({
  formType = 'login',
  handleClose = undefined,
  navigateAdminSettings = false,
}: LoginProps): JSX.Element => {
  const navigate = useNavigate();
  const { token, setToken } = useToken();
  const [imageIsReady, setImageIsReady] = useState(false);
  const [errorMsg, showErrorMsg] = useState(false);
  const [msg, setMsg] = useState('');
  const [form, setForm] = useState(formType);
  const [redirect, setRedirect] = useState(null);
  const [userList, setUserList] = useState([]);
  const [getUsersQuery] = useLazyQuery(USER_LIST, {
    context: { uri: PHOTON_GRAPH_URL },
  });

  const logoSource = '/assets/skill-up-logo-horizontal.svg';
  const image = new Image();
  image.src = logoSource;
  image.onload = () => {
    setImageIsReady(true);
  };
  useEffect(() => {
    getUsersQuery({
      onCompleted: data => {
        setUserList(
          data.userAccounts.edges.map((item: any) => ({
            id: item.node.id,
            email: item.node.email,
          }))
        );
      },
    });
  }, [getUsersQuery]);

  // Redirect the user if they are authenticated
  useEffect(() => {
    if (token && featureFlagManager.featureEnabled(FLAG.INTERNAL_ONLY)) {
      navigate('/home');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (token && handleClose) {
      handleClose();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [token]);

  useEffect(() => {
    setForm(formType);
  }, [formType]);

  return (
    <Root className={classes.base}>
      <Box className={classes.boxMain}>
        <Typography
          variant="h5"
          fontWeight='bold'
          sx={{ marginBottom: '20px', textAlign: 'center' }}
        ><span style={{ color: '#081e73ff' }}>Skill</span><span style={{ color: '#208255ff' }}>Mapping</span></Typography>
        <Typography
          variant="h6"
          sx={{ marginBottom: '20px', textAlign: 'center' }}
        >
          {form === 'login'
            ? 'Log In'
            : form === 'register'
              ? 'Register For Access'
              : 'Forgot Password'}
        </Typography>
        {msg && (
          <Alert
            severity={errorMsg ? 'error' : 'info'}
            sx={{ marginBottom: '20px' }}
          >
            {msg}
          </Alert>
        )}
        {form === 'login' ? (
          <LoginForm
            setForm={setForm}
            setMsg={setMsg}
            showErrorMsg={showErrorMsg}
            redirect={redirect}
            setRedirect={setRedirect}
            hideLogin={setToken}
          />
        ) : form === 'register' ? (
          <RegisterForm
            setForm={setForm}
            setMsg={setMsg}
            showErrorMsg={showErrorMsg}
            users={userList}
            redirect={redirect}
            setRedirect={setRedirect}
            admin={null}
          />
        ) : (
          form === 'forgot-password' && (
            <ForgotPassword
              setForm={setForm}
              setMsg={setMsg}
              errorMsg={errorMsg}
              showErrorMsg={showErrorMsg}
            />
          )
        )}
      </Box>
    </Root>
  );
};
