import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import InputAdornment from "@mui/material/InputAdornment";
import AccountCircle from "@mui/icons-material/AccountCircle";
import Button from "@mui/material/Button";
import Divider from "@mui/material/Divider";

import { classes, Form } from "../style";
import { Typography } from "@mui/material";

import { gql, useLazyQuery, useMutation } from "@apollo/client";

import { PHOTON_GRAPH_URL } from "../../../environmentConfig";
import PasswordInput from "../components/PasswordInput";
import useEmailValidation from "../../../hooks/useEmailValidation";
import { signInWithGooglePopup } from "../../../firebaseApp";

const bcrypt = require("bcryptjs");

const updateUserData = gql`
  mutation INCREMENT_USER_COUNT($email: String!) {
    incrementCount(input: { _email: $email }) {
      clientMutationId
    }
    updateLastLogInDate(input: { _email: $email }) {
      clientMutationId
    }
  }
`;

const isValidUser = gql`
  query GET_USER($email: String!) {
    userAccounts(filter: { email: { equalToInsensitive: $email } }) {
      nodes {
        id
        email
        firstname
        lastname
        photoUrl
        password
        expired
        institution {
          name
        }
      }
    }
  }
`;

const getUserById = gql`
  query GET_USER_BY_ID($id: String!) {
    userAccount(id: $id) {
      id
      email
      firstname
      lastname
      photoUrl
      expired
      approved
      institution {
        name
      }
    }
  }
`;

const getRoles = gql`
  query GET_ROLES($id: String!) {
    userRoleMappings(condition: {userId: $id}) {
      nodes {
        role {
          role
        }
      }
    }
  }
`;

const UPDATE_EMAIL = gql`
  mutation UpdateEmail($id: String!, $email: String) {
    updateEmail(input: { _id: $id, _email: $email }) {
      affectedrows
    }
  }
`;

const UPDATE_FIELD = gql`
  mutation UpdateField($email: String!, $field: String, $value: String) {
    updateField(
      input: { _email: $email, _fieldName: $field, _fieldValue: $value }
    ) {
      boolean
    }
  }
`;

interface LoginFormProps {
  setForm: any;
  setMsg: any;
  showErrorMsg: any;
  redirect: any;
  setRedirect: any;
  hideLogin: any;
}

export const LoginForm = ({
  setForm,
  setMsg,
  showErrorMsg,
  redirect = null,
  setRedirect,
  hideLogin,
}: LoginFormProps): JSX.Element => {
  const navigate = useNavigate();

  const [password, setPassword] = useState("");
  const { email, setEmail, validateEmail } = useEmailValidation("", null);

  const [checkAccount] = useLazyQuery(getUserById, {
    context: {
      uri: PHOTON_GRAPH_URL,
    },
    fetchPolicy: "network-only",
  });

  const [accountQuery] = useLazyQuery(isValidUser, {
    variables: { email: email.toLowerCase() },
    context: {
      uri: PHOTON_GRAPH_URL,
    },
  });

  const [updateLoginFrequency] = useMutation(updateUserData, {
    context: {
      uri: PHOTON_GRAPH_URL,
    },
  });

  const [updateEmail] = useMutation(UPDATE_EMAIL, {
    context: { uri: PHOTON_GRAPH_URL },
  });

  const [updateField] = useMutation(UPDATE_FIELD, {
    context: { uri: PHOTON_GRAPH_URL },
  });

  const [getUserRoles] = useLazyQuery(getRoles, {
    context: { uri: PHOTON_GRAPH_URL },
  });


  const handleForm = (type: any) => {
    setForm(type);
    setPassword("");
    setEmail("");
    setMsg("");
    showErrorMsg(false);
  };

  useEffect(() => {
    if (redirect) {
      handleGoogleLogin(redirect);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    validateEmail();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [email]);

  const login = (user: any, roleList: any) => {
    const currentUser = {
      uid: user.id,
      email: user.email,
      firstname: user.firstname,
      lastname: user.lastname,
      photoUrl: user.photoUrl,
      institution: user.institution.name,
      psiAdmin: roleList.includes('psi_admin')
    };
    updateLoginFrequency({ variables: { email: currentUser.email } });
    hideLogin(currentUser);
    window.dispatchEvent(new Event("login"));
  };

  const handleLogin = async (event: any) => {
    event.preventDefault();
    let result = await accountQuery({ variables: { email: email } });
    let roleList: any[];
    roleList = [];
    if (result.data.userAccounts.nodes.length > 0) {
      const expired = result.data.userAccounts.nodes[0];
      let roles = await getUserRoles({ variables: { id: result.data.userAccounts.nodes[0].id}});
      if (roles.data.userRoleMappings.nodes.length > 0) {
        for(let i = 0; i < roles.data.userRoleMappings.nodes.length; i++) {
          roleList.push(roles.data.userRoleMappings.nodes[i].role.role);
        }
      }
      if (!roleList.includes("psi_admin")) {
        setMsg(
          "This account has is not approved for the Skill Mapping application. If you require access or have any questions please contact us at skillup@cybera.ca"
        );
        showErrorMsg(true);
        return;
      }
      if (expired && new Date(expired).getTime() <= new Date().getTime()) {
        setMsg(
          "This account has expired. If you require an extension or have any questions please contact us at skillup@cybera.ca"
        );
        showErrorMsg(true);
        return;
      }
      bcrypt
        .compare(password, result.data.userAccounts.nodes[0].password)
        .then((res: any) => {
          if (res) {
            login(result.data.userAccounts.nodes[0], roleList);
            navigate("/home");
          } else {
            setMsg("Invalid Email or Password. Please try again.");
            showErrorMsg(true);
          }
        })
        .catch((err: any) => console.error(err.message));
    } else {
      setMsg("Invalid Email or Password. Please try again.");
      showErrorMsg(true);
    }
  };

  const handleGoogleLogin = async (redirected: any) => {
    // Handle case if user is redirected from registration
    let response;
    if (redirected) {
      response = redirected;
    } else {
      response = await signInWithGooglePopup();
    }

    if (response) {
      let result = await checkAccount({ variables: { id: response.uid } });

      if (result.data.userAccount) {
        const {
          expired,
          approved,
          firstname,
          lastname,
          email,
          photoUrl,
        } = result.data.userAccount;

        if (expired && new Date(expired).getTime() <= new Date().getTime()) {
          setMsg(
            "This account has expired. If you require an extension or have any questions please contact us at skillup@cybera.ca"
          );
          showErrorMsg(true);
          return;
        }

        // Handle case where existing oAuth users do not have information stored in database
        if (!email) {
          updateEmail({
            variables: {
              id: response.uid,
              email: response.email,
            },
          });
        }

        if (!firstname || !lastname) {
          updateField({
            variables: {
              email: response.email,
              field: "firstname",
              value: response.displayName.split(" ")[0],
            },
          });

          updateField({
            variables: {
              email: response.email,
              field: "lastname",
              value: response.displayName.split(" ")[1],
            },
          });
        }

        if (!photoUrl) {
          updateField({
            variables: {
              email: response.email,
              field: "photo_url",
              value: response.photoURL,
            },
          });
        }

        let roles = await getUserRoles({ variables: { id: result.data.userAccount.id}});
        let roleList = [];
        if (roles.data.userRoleMappings.nodes.length > 0) {
          for(let i = 0; i < roles.data.userRoleMappings.nodes.length; i++) {
            roleList.push(roles.data.userRoleMappings.nodes[i].role.role);
          }
        }

        if (approved && roleList.includes("psi_admin")) {
          login(result.data.userAccount, roleList);
          navigate("/home");
        } else if (approved === false) {
          setMsg(
            "This account has is not approved for the Skill Mapping application. If you require access or have any questions please contact us at skillup@cybera.ca"
          );
          showErrorMsg(true);
          return;
        } else if (!roleList.includes("psi_admin")) {
          setMsg(
            "We regret to inform you that you have not been approved to use this application. If you have any questions please contact us at skillup@cybera.ca"
          );
          showErrorMsg(true);
          return;
        } else {
          setMsg(
            "This account is awaiting approval. If you have any questions please contact us at skillup@cybera.ca"
          );
          showErrorMsg(true);
          return;
        }
      } else {
        // Redirect user to registration
        setRedirect(response);
        handleForm("register");
      }
    }
  };

  return (
    <Form className={classes.container}>
      <form>
        <TextField
          required
          value={email}
          label="Email"
          placeholder="Email"
          autoComplete="username"
          inputProps={{ inputMode: "email" }}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <AccountCircle />
              </InputAdornment>
            ),
          }}
          variant="outlined"
          style={{ marginBottom: "20px", width: "100%" }}
          autoFocus={true}
          onChange={(event: any) => {
            setEmail(event.target.value);
            setMsg("");
            showErrorMsg(false);
          }}
          onKeyUp={(eve: any) => {
            if (eve.key === "Enter") handleLogin(eve);
          }}
        />
        <PasswordInput
          label="Password"
          placeholder="Password"
          style={{ width: "100%" }}
          onChange={(event: any) => {
            setPassword(event.target.value);
            setMsg("");
            showErrorMsg(false);
          }}
          onKeyPress={(eve: any) => {
            if (eve.key === "Enter") handleLogin(eve);
          }}
          tooltipIcons={{
            passwordLengthIcon: null,
            passwordLowercaseIcon: null,
            passwordUppercaseIcon: null,
            passwordNumberIcon: null,
            passwordSpecialCharacterIcon: null,
          }}
          passwordReset={false}
        />
      </form>
      <Button
        variant="contained"
        style={{ marginTop: "20px" }}
        onClick={handleLogin}
      >
        Log in
      </Button>
      <Grid
        container
        className={classes.textContainer}
        style={{ margin: "20px 0px" }}
      >
        <Typography
          className={classes.linkText}
          onClick={() => handleForm("forgot-password")}
        >
          Forgot password?
        </Typography>
      </Grid>
      <Divider>or</Divider>
      <Button
        variant="outlined"
        style={{ margin: "20px 0px 10px", color: "black" }}
        onClick={() => handleGoogleLogin(null)}
      >
        <img
          alt=""
          src="/assets/google-logo.png"
          style={{ marginRight: "5px" }}
          height={20}
          width={20}
        />
        Continue With Google
      </Button>
      <Button variant="text" onClick={() => handleForm("register")}>
        Don't have an account? Register for Access
      </Button>
    </Form>
  );
};
