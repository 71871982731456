export { default as FLAG } from './flags';
class FeatureFlagManager {
  flags;

  initialize(flags) {
    const featureFlags = flags.reduce((acc, flag) => {
      const { feature, isActive } = flag;
      return {
        ...acc,
        [feature]: isActive,
      };
    }, {});
    
    this.flags = featureFlags;
  }

  featureEnabled(name) {
    return this.flags[name];
  }
}

const featureFlagManager = new FeatureFlagManager();
export default featureFlagManager;

export const Feature = ({ name, children }) =>
  featureFlagManager.featureEnabled(name) ? children : null;

