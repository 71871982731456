import { Grid } from "@mui/material";

import { SkillType } from "../../helper";
import { CustomGrid, classes } from "./style";

import { useSetState } from 'react-use';
import Joyride, { CallBackProps, STATUS, Step } from 'react-joyride';
import { FixMeLater } from "../../../../any";

interface State {
  run: boolean;
  stepList: Step[];
}

export interface OrganizeSkillsPageProps {
  selectedSkills: SkillType[];
  handleSelectSkill: (skill: SkillType) => void;
  tutorial: boolean;
  setTutorial: FixMeLater;
}

export const OrganizeSkills = ({
  selectedSkills,
  handleSelectSkill,
  tutorial,
  setTutorial
}: OrganizeSkillsPageProps) => {
  const [{ stepList }, setState] = useSetState<State>({
    run: tutorial,
    stepList: [
      {
        content: <div>
            <p>
              In this step, you can classify the selected skills that can be acquired in the 
              selected program as either guaranteed or optional skills.
            </p>
          </div>,
        placement: 'center',
        target: 'body',
      },
      {
        content: <div>
            <p>
              This panel contains a list of skills that are considered guaranteed from the program.
              By default, the entire selected list will be added here.
            </p>
          </div>,
        spotlightPadding: 0,
        placement: 'right',
        target: '#guaranteedList',
      },
      {
        content: <div>
        <p>
          This panel contains a list of skills that are considered optional from the program.
        </p>
          </div>,
        spotlightPadding: 0,
        placement: 'left',
        target: '#optionalList',
      },
      {
        content: <div>
        <p>
          You can move certain skills from either panel to configure which are guaranteed
          and which are optional by using these buttons.
        </p>
          </div>,
        spotlightPadding: 0,
        placement: 'left',
        target: '#arrows',
      },
      {
        content: <div>
            <p>
              Once you have completed your selections, you can continue to the next step.
            </p>
          </div>,
        spotlightPadding: 0,
        placement: 'top',
        target: '#nextButton',
      },
      {
        content: <div>
            <p>
              You can return to the previous step at any time. All of your changes will be saved.
            </p>
          </div>,
        spotlightPadding: 0,
        placement: 'top',
        target: '#previousButton',
      },
      {
        content: <div>
            <p>
              You may also skip this step if you do not wish configure you skill types. Note that 
              this will mark all of your skill selections as guaranteed.
            </p>
          </div>,
        spotlightPadding: 0,
        placement: 'top',
        target: '#skipButton',
      },
    ],
  });


  const handleJoyrideCallback = (data: CallBackProps) => {
    const { status, type } = data;
    const finishedStatuses: string[] = [STATUS.FINISHED, STATUS.SKIPPED];

    if (finishedStatuses.includes(status)) {
      setTutorial(false);
    }
  };
  
  return (
    <CustomGrid container columnSpacing={2}>
      <Joyride
        callback={handleJoyrideCallback}
        continuous
        hideCloseButton
        run={tutorial}
        scrollToFirstStep
        showProgress
        showSkipButton
        steps={stepList}
        styles={{
          options: {
            arrowColor: '#EBEBEB',
            backgroundColor: '#EBEBEB',
            primaryColor: '#76A472',
            zIndex: 10000,
          },
        }}
      />
      <Grid item xs={12}>
        <Grid container className={classes.container}></Grid>
      </Grid>
    </CustomGrid>
  );
};
