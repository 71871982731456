import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { gql, useMutation, useLazyQuery, useQuery } from "@apollo/client";

import {
  TextField,
  Button,
  Divider,
  ToggleButton,
  ToggleButtonGroup,
  Typography,
} from "@mui/material";

import useMediaQuery from "@mui/material/useMediaQuery";

import { classes, Form } from "../style";

import featureFlagManager, {
  FLAG,
} from "../../../modules/featureFlagManager/index.jsx";
import { PHOTON_GRAPH_URL } from "../../../environmentConfig.js";

import useEmailValidation from "../../../hooks/useEmailValidation.js";
import {
  sendUserRegistrationAlert,
  sendResetPasswordEmail,
} from "../../../services/passwordResetService.js";
import { DropdownSelector } from "../../../modules/dropdownSelector";
import { signInWithGooglePopup } from "../../../firebaseApp.js";
import { RegisterSuccess } from "./components/registerSuccess";
import { RegisterWithGoogle } from "./components/registerGoogle";

import bcrypt from "bcryptjs";
import { v4 as uuidv4 } from "uuid";

const INSERT_USER = gql`
  mutation SetUser(
    $id: String
    $email: String!
    $firstname: String!
    $lastname: String!
    $password: String!
    $description: String!
    $institution: Int!
    $createdBy: String
    $approved: Boolean
  ) {
    insertUser(
      input: {
        _id: $id
        _email: $email
        _firstname: $firstname
        _lastname: $lastname
        _pwd: $password
        _descr: $description
        _institution: $institution
        _createdby: $createdBy
        _approved: $approved
      }
    ) {
      boolean
    }
  }
`;

const INSERT_USER_GOOGLE = gql`
  mutation SetUser(
    $id: String
    $email: String!
    $firstname: String!
    $lastname: String!
    $description: String!
    $institution: Int!
    $createdBy: String
    $approved: Boolean
    $photourl: String
  ) {
    insertUser(
      input: {
        _id: $id
        _email: $email
        _firstname: $firstname
        _lastname: $lastname
        _descr: $description
        _institution: $institution
        _createdby: $createdBy
        _approved: $approved
        _photourl: $photourl
      }
    ) {
      boolean
    }
  }
`;

export const ADD_ROLE = gql`
  mutation addRole($id: String!, $role: Int) {
    addUserRole(input: { _id: $id, _role: $role }) {
      boolean
    }
  }
`;

const CHECK_USER = gql`
  query GetUser($email: String!) {
    userAccountByEmail(email: $email) {
      id
    }
  }
`;

const GET_INSTITUTIONS = gql`
  query getInstitutions {
    institutions(condition: { psiFlag: true }) {
      nodes {
        id
        name
        psiFlag
      }
    }
  }
`;

const GET_ROLES = gql`
  query GetRoles {
    roles(condition: {role: "psi_admin"}) {
      edges {
        node {
          id
          role
        }
      }
    }
  }
`;

interface RegisterFormProps {
  setForm: any;
  setMsg: any;
  showErrorMsg: any;
  admin: any;
  users: any;
  redirect: any;
  setRedirect: any;
}

export const RegisterForm = ({
  setForm,
  setMsg,
  showErrorMsg,
  admin,
  users,
  redirect = null,
  setRedirect,
}: RegisterFormProps): JSX.Element => {
  const navigate = useNavigate();

  const [userInfo, setUserInfo] = useState(redirect);
  const [firstname, setFirstname] = useState("");
  const [lastname, setLastname] = useState("");
  const [description, setDescription] = useState("");
  const [userType, setUserType] = useState("psi_admin");
  const [roles, setRoles] = useState([]);
  const [hasAttemptedSubmit, setHasAttemptedSubmit] = useState(false);
  const { email, setEmail, emailError, validateEmail } = useEmailValidation(
    "",
    users ? users : []
  );
  const [registerSuccess, setRegisterSuccess] = useState(false);
  const [selectedInstitution, setSelectedInstitution] = useState("");
  const saltRounds = 10;

  const [addRole] = useMutation(ADD_ROLE, {
    context: { uri: PHOTON_GRAPH_URL },
  });

  const [insertUser] = useMutation(INSERT_USER, {
    context: { uri: PHOTON_GRAPH_URL },
  });

  const [insertUserGoogle] = useMutation(INSERT_USER_GOOGLE, {
    context: { uri: PHOTON_GRAPH_URL },
  });

  const [checkUser] = useLazyQuery(CHECK_USER, {
    context: { uri: PHOTON_GRAPH_URL },
  });

  const [getRoles] = useLazyQuery(GET_ROLES, {
    context: { uri: PHOTON_GRAPH_URL },
  });

  const { loading, data } = useQuery(GET_INSTITUTIONS, {
    context: { uri: PHOTON_GRAPH_URL },
  });

  useEffect(() => {
    validateEmail();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [email]);

  useEffect(() => {
    getRoles({
      onCompleted: data => {
        setRoles(data.roles.edges[0].node.id);
      },
    });
  }, []);

  // Validation errors
  const firstnameError =
    hasAttemptedSubmit && firstname === "" ? "Firstname is required" : "";
  const lastnameError =
    hasAttemptedSubmit && lastname === "" ? "Lastname is required" : "";
  const institutionError =
    hasAttemptedSubmit && selectedInstitution === ""
      ? "Institution is Required"
      : "";

  function generateRandomPassword(length = 12) {
    const chars =
      "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
    let result = "";
    for (let i = 0; i < length; i++) {
      result += chars.charAt(Math.floor(Math.random() * chars.length));
    }
    return result;
  }

  const handleRegister = async () => {
    setHasAttemptedSubmit(true);
    if (!validateEmail()) {
      setMsg(emailError);
      showErrorMsg(true);
      return;
    } else if (firstnameError || lastnameError || institutionError) {
      setMsg("Please fill in all fields correctly");
      showErrorMsg(true);
      return;
    }

    // Generate a temporary random password
    const tempPassword = generateRandomPassword();

    try {
      const hash = await bcrypt.hash(tempPassword, saltRounds);
      const uid = uuidv4();

      await insertUser({
        variables: {
          id: uid, //generates a unique UUID

          email: email.toLowerCase(),
          password: hash,
          firstname,
          lastname,
          description,
          institution: selectedInstitution,
          createdBy: null,
          approved: !featureFlagManager.featureEnabled(FLAG.INTERNAL_ONLY)
            ? true
            : null,
        },
      });
      addRole({
        variables: {
          id: uid,
          role: roles
        }
      });

      setRegisterSuccess(true);
      showErrorMsg(false);
      setMsg("");
      sendUserRegistrationAlert({
        email,
        firstname,
        lastname,
        institution: selectedInstitution,
      });
      if (!featureFlagManager.featureEnabled(FLAG.INTERNAL_ONLY)) {
        await sendResetPasswordEmail(
          { firstname, email, password: hash },
          true
        );
      }
    } catch (err: any) {
      console.error(err.message);
      setMsg("Error in registration");
      showErrorMsg(true);
    }
  };

  const handleGoogle = async () => {
    const response = await signInWithGooglePopup();

    if (response) {
      let user = await checkUser({ variables: { email: response.email } });
      // Redirect user to login
      if (user.data.userAccountByEmail) {
        setRedirect(response);
        handleForm("login");
      } else {
        setUserInfo(response);
        setMsg("");
        showErrorMsg(false);
      }
    }
  };

  const handleGoogleRegister = async () => {
    try {
      await insertUserGoogle({
        variables: {
          id: userInfo.uid,
          email: userInfo.email,
          firstname: userInfo.displayName.split(" ")[0],
          lastname: userInfo.displayName.split(" ")[1],
          description,
          institution: selectedInstitution,
          createdBy: null,
          approved: !featureFlagManager.featureEnabled(FLAG.INTERNAL_ONLY)
            ? true
            : null,
          photourl: userInfo.photoURL,
        },
      });
      addRole({
        variables: {
          id: userInfo.uid,
          role: roles
        }
      });

      setRegisterSuccess(true);
      showErrorMsg(false);
      setMsg("");
      sendUserRegistrationAlert({
        email: userInfo.email,
        firstname: userInfo.displayName.split(" ")[0],
        lastname: userInfo.displayName.split(" ")[1],
        institution: selectedInstitution,
      });
    } catch (err: any) {
      console.error(err.message);
      setMsg("Error in registration");
      showErrorMsg(true);
    }
  };

  const isSmallScreen = useMediaQuery(
    `(max-width:${localStorage.getItem("MD_SCREEN")})`
  );

  const handleForm = (type: any) => {
    if (isSmallScreen && featureFlagManager.featureEnabled(FLAG.INTERNAL_ONLY)) {
      navigate("/" + type);
    }
    setForm(type);
    setMsg("");
    showErrorMsg(false);
  };

  const handleToggle = (event: any, newUser: any) => {
    if (newUser !== null) {
      setUserType(newUser);
    }
  };

  let institutionOptions: any = [];

  if (!loading) {
    let institutions = [...data.institutions.nodes];
    institutionOptions = institutions
      .sort((a, b) => {
        if (a.psiFlag !== b.psiFlag) {
          return a.psiFlag ? 1 : -1;
        } else {
          return a.name.localeCompare(b.name);
        }
      })
      .map((institute) => ({
        label: institute.name,
        value: institute.id,
        postSecondary: "",
        disabled: false,
      }));
  }

  return (
    <Form className={classes.container}>
      {registerSuccess ? (
        <RegisterSuccess handleForm={handleForm} setRedirect={setRedirect} />
      ) : userInfo === null ? (
        <>
          <form>
            <div style={{ display: "flex", marginBottom: "20px" }}>
              <TextField
                id="outlined-basic"
                label="First Name"
                value={firstname}
                onChange={(e) => setFirstname(e.target.value)}
                error={!!firstnameError}
                style={{ marginRight: "20px", flex: "1" }}
                variant="outlined"
                autoComplete="given-name"
              />
              <TextField
                id="outlined-basic"
                label="Last Name"
                value={lastname}
                onChange={(e) => setLastname(e.target.value)}
                error={!!lastnameError}
                style={{ flex: "1" }}
                variant="outlined"
                autoComplete="family-name"
              />
            </div>
            <TextField
              id="outlined-basic"
              label="Email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              error={hasAttemptedSubmit && !!emailError}
              style={{ marginBottom: "20px", width: "100%" }}
              variant="outlined"
              autoComplete="email"
              inputProps={{ inputMode: "email" }}
            />
            <TextField
              id="outlined-basic"
              label="Intended Use (Optional)"
              value={description}
              onChange={(e) => setDescription(e.target.value)}
              placeholder="Ex: Your role and purpose for using SkillUp"
              style={{ marginBottom: "20px", width: "100%" }}
              variant="outlined"
            />

            <DropdownSelector
              options={institutionOptions}
              id="institution-select"
              value={selectedInstitution}
              label={"Institution"}
              onChange={(value: any) => setSelectedInstitution(value)}
              group={"postSecondary"}
              dropdownStyles={null}
            />
            {admin?.superuser && (
              <>
                <Typography sx={{ marginTop: "20px" }}>User Type</Typography>
                <div>
                  <ToggleButtonGroup
                    color="primary"
                    size="small"
                    value={userType}
                    onChange={handleToggle}
                    exclusive
                  >
                    <ToggleButton value="regular">Regular</ToggleButton>
                    <ToggleButton value="admin">Admin</ToggleButton>
                  </ToggleButtonGroup>
                </div>
              </>
            )}
          </form>
          <Button
            variant="contained"
            style={{ margin: "20px 0px" }}
            onClick={handleRegister}
            disabled={!firstname || !lastname || !email || !selectedInstitution}
          >
            {admin ? "Add User" : "Register for Access"}
          </Button>
          {!admin && (
            <>
              <Divider>or</Divider>
              <Button
                variant="outlined"
                style={{ margin: "20px 0px 10px", color: "black" }}
                onClick={() => handleGoogle()}
              >
                <img
                  alt=""
                  src="/assets/google-logo.png"
                  style={{ marginRight: "5px" }}
                  height={20}
                  width={20}
                />
                Continue With Google
              </Button>
              <Button variant="text" onClick={() => handleForm("login")}>
                Already have an account? Log in
              </Button>
            </>
          )}
        </>
      ) : (
        <RegisterWithGoogle
          userInfo={userInfo}
          description={description}
          setDescription={setDescription}
          institutionOptions={institutionOptions}
          selectedInstitution={selectedInstitution}
          setSelectedInstitution={setSelectedInstitution}
          handleGoogleRegister={handleGoogleRegister}
        />
      )}
    </Form>
  );
};
