import { styled, Grid } from "@mui/material";
import { FixMeLater } from "../../../../any";

const PREFIX = "SuggestedSkillsPage";

export const classes = {
  flex: `${PREFIX}-flex`,
  leftSide: `${PREFIX}-leftSide`,
  rightSide: `${PREFIX}-rightSide`,
};

export const CustomGrid = styled(Grid)(({ theme }: FixMeLater) => ({
  height: "100%",
  [`& .${classes.flex}`]: {
    display: "flex",
  },
  [`& .${classes.leftSide}`]: {
    flexGlow: 1,
    padding: "10px",
    borderRadius: "4px",
    backgroundColor: theme.palette.white.main,
    boxShadow:
      "0px 3px 1px -2px rgba(0,0,0,0.2), 0px 2px 2px 0px rgba(0,0,0,0.14), 0px 1px 5px 0px rgba(0,0,0,0.12)",
  },
  [`& .${classes.rightSide}`]: {
    flexGlow: 1,
    padding: "10px",
    borderRadius: "4px",
    backgroundColor: "#F3F3F3",
    boxShadow:
      "0px 3px 1px -2px rgba(0,0,0,0.2), 0px 2px 2px 0px rgba(0,0,0,0.14), 0px 1px 5px 0px rgba(0,0,0,0.12)",
  },
}));
