import React, { useState } from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

import { styled } from '@mui/material';

const CustomDialogTitle = styled(DialogTitle)(({ color, theme }) => ({
  '&.MuiDialogTitle-root': {
    color: theme.palette[color].main+' !important',
    textAlign: 'center'
  },
}));

export default function AlertDialog({
  title,
  body,
  yes = 'Delete',
  no = 'Cancel',
  color = 'skillMapping',
  open,
  onConfirm,
  onClose,
  setInput,
  error,
}) {

  return (
    <Dialog
      open={open}
      onClose={onClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      style={{padding: "20px 8px"}}
    >
      <CustomDialogTitle 
        color={color}
        variant='h5'
      >
        {title}
      </CustomDialogTitle>
      <DialogContent style={{padding: "5px 24px"}}>
        <DialogContentText id="alert-dialog-description">
          {body}
        </DialogContentText>
      </DialogContent>
      {setInput &&
        <DialogContent style={{padding: "5px 24px"}}>
          <input onChange={(e) => {setInput(e.target.value)}}
          style={{
            width: "80%",
            padding: "10px",
            borderRadius: "5px",
            fontSize: "medium",
            border: "2px solid rgb(190, 190, 190)",
            display: "block",
            margin: "0 auto",
          }}></input>
        </DialogContent>
      }
      {error &&
        <DialogContent style={{padding: "5px 24px"}}>
        <DialogContentText id="alert-dialog-description" style={{color: "red"}}>
          * {error}
          </DialogContentText>
      </DialogContent>
      }
      <DialogActions style={{padding: "20px 8px"}}>
        <Button 
          onClick={onClose} 
          color={color}
          variant="outlined"
          style={{ width: '100px' }}
        >
          {no}
        </Button>
        <Button 
          onClick={onConfirm} 
          color="red"
          variant="contained"
          style={{ width: '100px' }}
          autoFocus
        >
          {yes}
        </Button>
      </DialogActions>
    </Dialog>
  );
}
