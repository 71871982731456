import { gql } from "@apollo/client";

import { ProgramsType } from "../Programs/dataGrid/dataRows";
import { FixMeLater } from "../../any";

import { OccupationSkills } from "./pages/occupationSkills";
import { SuggestedSkills } from "./pages/suggestedSkills";
import { OrganizeSkills } from "./pages/organizeSkills";
import { ReviewSkills } from "./pages/reviewSkills";
import { OccupationType } from "./pages/occupationSkills/helper";

export const PROGRAM_INFO = gql`
  query GetProgramInfo($id: Int!) {
    program(id: $id) {
      id
      created
      edited
      field
      name
      offered {
        id
        name
      }
      type
      duration
      createdBy {
        id
        firstname
        lastname
        photoUrl
      }
      lastEdited {
        id
        firstname
        lastname
        photoUrl
      }
      hardSkillCrosswalks {
        nodes {
          skill {
            id
            name
          }
        }
      }
      softSkillCrosswalks {
        nodes {
          skill {
            id
            name
          }
        }
      }
    }
  }
`;

export const UPDATE_SKILLS = gql`
  mutation UpdateSkills(
    $type: String!
    $programId: Int!
    $skills: [Int!]
    $userId: String!
  ) {
    updateSkillCrosswalk(
      input: {
        _type: $type
        _programId: $programId
        _skills: $skills
        _editingUserId: $userId
      }
    ) {
      boolean
    }
  }
`;

export interface SkillType {
  id: number;
  name: string;
  type: string;
  value: number;
}

// Combine skills to show on front end
export const getSkills = (
  softSkills: FixMeLater[],
  hardSkills: FixMeLater[]
) => {
  let skills = softSkills
    .map((skill: FixMeLater) => ({
      id: skill.skill.id,
      name: skill.skill.name,
      type: "soft",
      value: 0,
    }))
    .concat(
      hardSkills.map((skill: FixMeLater) => ({
        id: skill.skill.id,
        name: skill.skill.name,
        type: "hard",
        value: 0,
      }))
    );

  return skills;
};

export const getPageIndex = (path: string) => {
  switch (path) {
    case "occupations":
      return 0;
    case "suggested":
      return 1;
    case "organize":
      return 2;
    case "review":
      return 3;
    default:
      return 0;
  }
};

export const getPageComponent = (
  index: number,
  programInfo: ProgramsType,
  selectedSkills: SkillType[],
  handleSelectSkill: (skill: SkillType) => void,
  handleSelectAll: (occupation: OccupationType) => void,
  handleClearAll: (occupation: OccupationType) => void,
  tutorial: boolean,
  setTutorial: FixMeLater
) => {
  switch (index) {
    case 0:
      return (
        <OccupationSkills
          selectedSkills={selectedSkills}
          handleSelectSkill={handleSelectSkill}
          handleSelectAll={handleSelectAll}
          handleClearAll={handleClearAll}
          tutorial={tutorial}
          setTutorial={setTutorial}
        />
      );
    case 1:
      return (
        <SuggestedSkills
          selectedSkills={selectedSkills}
          handleSelectSkill={handleSelectSkill}
          handleSelectAll={handleSelectAll}
          handleClearAll={handleClearAll}
          tutorial={tutorial}
          setTutorial={setTutorial}
        />
      );
    case 2:
      return (
        <OrganizeSkills
          selectedSkills={selectedSkills}
          handleSelectSkill={handleSelectSkill}
          tutorial={tutorial}
          setTutorial={setTutorial}
        />
      );
    case 3:
      return (
        <ReviewSkills
          programInfo={programInfo}
          selectedSkills={selectedSkills}
          tutorial={tutorial}
          setTutorial={setTutorial}
        />
      );
    default:
      return (
        <OccupationSkills
          selectedSkills={selectedSkills}
          handleSelectSkill={handleSelectSkill}
          handleSelectAll={handleSelectAll}
          handleClearAll={handleClearAll}
          tutorial={tutorial}
          setTutorial={setTutorial}
        />
      );
  }
};
