import React, { useContext } from "react";
import "./App.css";
import { AuthContext } from "./Auth";
import { Login } from "./apps/Login/Login";

import { PUBLIC_PATH, PHOTON_GRAPH_URL } from "./environmentConfig";
import { ROUTE } from "./routes";

import useToken from "./useToken";
import createApolloClient from "./apolloClient";
import featureFlagManager from "./modules/featureFlagManager";
import {
  Route,
  createBrowserRouter,
  createRoutesFromElements,
  RouterProvider,
  Outlet,
  Navigate,
} from "react-router-dom";
import {
  createTheme,
  ThemeProvider,
  StyledEngineProvider,
} from "@mui/material/styles";

import { gql, useQuery, ApolloProvider } from "@apollo/client";
import { ProgramsScene } from "./apps/Programs";
import { AddSkillsScene } from "./apps/AddSkills";
import { LoginModalProvider } from "./modules/loginModalContext/LoginModal";

const APP_CONFIG = gql`
  query App($theme: String!) {
    themes(condition: { description: $theme }) {
      nodes {
        value
      }
    }
    featureFlags {
      nodes {
        feature
        isActive
      }
    }
    lastUpdated
  }
`;

function PrivateRoute() {
  const { token } = useToken();

  if (!token) {
    return <Navigate to={`/${ROUTE.LOGIN}`} />;
  }

  return <Outlet />;
}

const BaseApp = () => {
  const { loading, data } = useQuery(APP_CONFIG, {
    variables: { theme: "default" },
    context: {
      uri: PHOTON_GRAPH_URL,
    },
  });

  if (loading) return <></>;

  const theme = createTheme(data.themes.nodes[0].value);
  featureFlagManager.initialize(data.featureFlags.nodes);

  localStorage.setItem("REFRESH_DATE", data.lastUpdated);
  localStorage.setItem("SM_SCREEN", "600px");
  localStorage.setItem("MD_SCREEN", "900px");
  localStorage.setItem("LG_SCREEN", "1200px");
  localStorage.setItem("XL_SCREEN", "1536px");

  return (
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={theme}>
        <LoginModalProvider>
          <main>
            <Outlet />
          </main>
        </LoginModalProvider>
      </ThemeProvider>
    </StyledEngineProvider>
  );
};

const router = createBrowserRouter(
  createRoutesFromElements(
    <Route element={<BaseApp />}>
      <Route element={<PrivateRoute />}>
        <Route path={`/${ROUTE.HOME}/*`} element={<ProgramsScene />} />
        <Route path={`/${ROUTE.ADD_SKILLS}/*`} element={<AddSkillsScene />} />
      </Route>
      <Route
        path="/*"
        element={
          <Login
            formType="login"
            handleClose={undefined}
            navigateAdminSettings={null}
          />
        }
      />
      <Route
        path={`/${ROUTE.LOGIN}/*`}
        element={
          <Login
            formType="login"
            handleClose={undefined}
            navigateAdminSettings={null}
          />
        }
      />
    </Route>
  ),
  {
    basename: `${PUBLIC_PATH}`,
  }
);

function App() {
  const loading = useContext(AuthContext);

  if (loading) return <></>;

  let token = "";
  const client = createApolloClient(token);

  return (
    <ApolloProvider client={client}>
      <RouterProvider router={router} />
    </ApolloProvider>
  );
}

export default App;
