import { useState, useEffect } from "react";

import { useLocation, useNavigate } from "react-router-dom";

import {
  Grid,
  Typography,
  Divider,
  Button,
  Stack,
  Skeleton,
} from "@mui/material";
import ArrowLeftIcon from "@mui/icons-material/ArrowCircleLeftOutlined";
import ArrowRightIcon from "@mui/icons-material/ArrowCircleRightOutlined";
import HelpIcon from '@mui/icons-material/Help';
import SaveIcon from "@mui/icons-material/Save";

import { useLazyQuery, useMutation } from "@apollo/client";
import { PHOTON_GRAPH_URL } from "../../environmentConfig";

import { CustomStepper, steps, StepProps } from "./stepper";
import {
  PROGRAM_INFO,
  UPDATE_SKILLS,
  SkillType,
  getSkills,
  getPageIndex,
  getPageComponent,
} from "./helper";
import { OccupationType } from "./pages/occupationSkills/helper";

import useToken from "../../useToken";
import { FixMeLater } from "../../any";
import { ProgramsType } from "../Programs/dataGrid/dataRows";

import { Root, PageContentGrid, classes } from "./style";

export const AddSkillsScene = () => {
  const { token } = useToken();
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const programId = Number(pathname.split("/")[2]);

  const [institution] = useState(token.institution);
  const [programInfo, setProgramInfo] = useState<ProgramsType | null>(null);
  const [page, setPage] = useState<StepProps>(
    steps[getPageIndex(pathname.split("/")[3])]
  );
  const [tutorial, setTutorial] = useState<boolean>(false);

  const storedSkillsString = sessionStorage.getItem("selectedSkills");

  const [storedSkills, setStoredSkills] = useState<SkillType[]>(
    storedSkillsString ? JSON.parse(storedSkillsString) : []
  );
  const [selectedSkills, setSelectedSkills] = useState<SkillType[]>([]);

  const [getProgramInfo] = useLazyQuery(PROGRAM_INFO, {
    variables: { id: programId },
    context: { uri: PHOTON_GRAPH_URL },
    fetchPolicy: "cache-and-network",
  });

  const [updateSkills] = useMutation(UPDATE_SKILLS, {
    context: { uri: PHOTON_GRAPH_URL },
  });

  useEffect(() => {
    getProgramInfo({
      onCompleted: (data: FixMeLater) => {
        const prog = {
          id: data.program.id,
          programId: data.program.id,
          created: data.program.created,
          edited: data.program.edited,
          field: data.program.field,
          name: data.program.name,
          institution: data.program.offered.name,
          type: data.program.type,
          duration: data.program.duration,
          createdBy: data.program.createdBy,
          lastEdited: data.program.lastEdited,
          hardSkills: data.program.hardSkillCrosswalks.nodes,
          softSkills: data.program.softSkillCrosswalks.nodes,
        };
        setProgramInfo(prog);

        /*
          storedSkills contains all changes made to selectedSkills and is saved in session storage.
          If the user just navigated to the page, then no changes have been made yet and storedSkills is empty, so we will show them the skills mapped to the program.
          Otherwise we will display the storedSkills (e.g. They are already in the flow, made changes, and refreshed the page)
        */
        if (storedSkills.length > 0) {
          setSelectedSkills(storedSkills);
        } else {
          setSelectedSkills(getSkills(prog.softSkills, prog.hardSkills));
        }
      },
    });
  }, []);

  useEffect(() => {
    sessionStorage.setItem("selectedSkills", JSON.stringify(selectedSkills));
    setStoredSkills(selectedSkills);
  }, [selectedSkills]);

  const handleSetPage = (step: StepProps) => {
    setPage(step);

    switch (step.index) {
      case 0:
        navigate(`/add-skills/${programId}/occupations`);
        break;
      case 1:
        navigate(`/add-skills/${programId}/suggested`);
        break;
      case 2:
        navigate(`/add-skills/${programId}/organize`);
        break;
      case 3:
        navigate(`/add-skills/${programId}/review`);
        break;
    }
  };

  const handleTutorialClick = () => {
    setTutorial(!tutorial);
  };

  const handleSelectSkill = (skill: SkillType) => {
    if (selectedSkills.some((selected) => selected.name === skill.name)) {
      setSelectedSkills((selected) =>
        selected.filter((item) => item.name !== skill.name)
      );
    } else {
      setSelectedSkills((oldSelected) => [...oldSelected, skill]);
    }
  };

  const handleSaveSkills = async () => {
    const hardSkills = selectedSkills.filter((skill) => skill.type === "hard");
    const softSkills = selectedSkills.filter((skill) => skill.type === "soft");

    await updateSkills({
      variables: {
        type: "hard",
        programId,
        skills: hardSkills.map((skill) => skill.id),
        userId: token.uid,
      },
    });

    await updateSkills({
      variables: {
        type: "soft",
        programId,
        skills: softSkills.map((skill) => skill.id),
        userId: token.uid,
      },
    });

    sessionStorage.removeItem("selectedSkills");
    navigate("/home", { state: { updated: true } });
  };

  const handleSelectAll = (occupation: OccupationType) => {
    const combinedSkills = [...selectedSkills, ...occupation.skills];
    const uniqueSkills = combinedSkills.filter(
      (value, index, self) =>
        index ===
        self.findIndex((t) => t.id === value.id && t.name === value.name)
    );
    setSelectedSkills(uniqueSkills);
  };

  const handleClearAll = (occupation: OccupationType) => {
    const filterSelected = selectedSkills.filter(
      (skill) =>
        !occupation.skills.some(
          (occupationSkill) => occupationSkill.name === skill.name
        )
    );
    setSelectedSkills(filterSelected);
  };

  const handleBack = () => {
    sessionStorage.removeItem("selectedSkills");
    navigate("/home");
  };

  return (
    <Root style={{backgroundColor: 'white'}}>
      <Grid container spacing={2}>
        <Grid item sm={4} md={3} lg={2}>
          <Grid container>
            <Grid item xs={12}>
              <Button
                className={classes.button}
                color="skillMapping"
                variant="contained"
                size="small"
                startIcon={<ArrowLeftIcon className={classes.buttonIcon} />}
                onClick={handleBack}
              >
                Back to Programs
              </Button>
            </Grid>
            <Grid item xs={12}>
              <CustomStepper
                pageIndex={page.index}
                handleSetPage={handleSetPage}
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid item sm={8} md={9} lg={10}>
          <Grid container>
            <Grid item xs={12} className={classes.headerText}>
              <Typography variant="h5">Add Skills</Typography>
              <Divider
                orientation="vertical"
                variant="middle"
                flexItem
                className={classes.headerDivider}
              />
              <Typography variant="h5">{institution}</Typography>
            </Grid>
            {programInfo ? (
              <>
                <Grid item xs={10} className={classes.subheaderText}>
                  <Typography variant="h6">
                    {programInfo.field}, {programInfo.name}
                  </Typography>
                </Grid>
                <Grid item xs={2}>
                  <button className={classes.tutorialButton} onClick={handleTutorialClick}>
                    <Grid container className={classes.tutorialContainer}>
                      <Grid item>
                        <Typography variant="subtitle1" className={classes.tutorialText}>
                          <i>Tutorial</i>
                        </Typography>
                      </Grid>
                      <Grid item>
                        <HelpIcon className={classes.tutorialIcon} />
                      </Grid>
                    </Grid>
                  </button>
                </Grid>
              </>
            ) : (
              <Stack spacing={1} sx={{ width: "100%" }}>
                <Skeleton variant="text" sx={{ fontSize: "2.5rem" }} />
              </Stack>
            )}
            {programInfo ? (
              <PageContentGrid item xs={12}>
                <Grid container>
                  <Grid item xs={12} className={classes.pageTitle}>
                    {page.title}
                  </Grid>
                  <Grid item xs={12} className={classes.pageBody}>
                    {getPageComponent(
                      page.index,
                      programInfo,
                      selectedSkills,
                      handleSelectSkill,
                      handleSelectAll,
                      handleClearAll,
                      tutorial,
                      setTutorial
                    )}
                  </Grid>
                  <Grid item xs={12} className={classes.pageButtons}>
                    {(page.index === 1 || page.index === 2) && (
                      <Button
                        className={classes.skipButton}
                        color="skillMapping"
                        variant="text"
                        size="small"
                        onClick={() => handleSetPage(steps[page.index + 1])}
                        id="skipButton"
                      >
                        {page.index === 1
                          ? "Skip"
                          : "Skip (Mark all skills as guaranteed)"}
                      </Button>
                    )}
                    <Button
                      className={classes.button}
                      sx={{ margin: "0px 20px" }}
                      color="skillMapping"
                      variant="outlined"
                      size="small"
                      startIcon={
                        <ArrowLeftIcon className={classes.buttonIcon} />
                      }
                      disabled={page.index === 0}
                      onClick={() => handleSetPage(steps[page.index - 1])}
                      id="previousButton"
                    >
                      Previous Step
                    </Button>
                    {page.index === steps.length - 1 ? (
                      <Button
                        className={classes.button}
                        color="skillMapping"
                        variant="contained"
                        size="small"
                        endIcon={<SaveIcon className={classes.buttonIcon} />}
                        disabled={selectedSkills.length === 0}
                        onClick={handleSaveSkills}
                        id="saveButton"
                      >
                        Save Skills
                      </Button>
                    ) : (
                      <Button
                        className={classes.button}
                        color="skillMapping"
                        variant="contained"
                        size="small"
                        endIcon={
                          <ArrowRightIcon className={classes.buttonIcon} />
                        }
                        onClick={() => handleSetPage(steps[page.index + 1])}
                        id="nextButton"
                      >
                        Next Step
                      </Button>
                    )}
                  </Grid>
                </Grid>
              </PageContentGrid>
            ) : (
              <Stack spacing={1} sx={{ width: "100%" }}>
                <Skeleton variant="rounded" width="100%" height={40} />
                <Skeleton variant="rounded" width="100%" height={150} />
                <Skeleton variant="rounded" width="100%" height={150} />
                <Skeleton variant="rounded" width="100%" height={150} />
                <Skeleton variant="rounded" width="100%" height={150} />
                <Skeleton variant="rounded" width="100%" height={150} />
                <Skeleton variant="rounded" width="100%" height={40} />
              </Stack>
            )}
          </Grid>
        </Grid>
      </Grid>
    </Root>
  );
};
