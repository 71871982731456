import { Typography, Button } from '@mui/material';

import featureFlagManager, { FLAG } from '../../../../modules/featureFlagManager/index';

interface RegisterSuccessProps {
  handleForm: any;
  setRedirect: any;
}

export const RegisterSuccess = ({ handleForm, setRedirect }: RegisterSuccessProps): JSX.Element => {
  const handleReturn = () => {
    setRedirect(null);
    handleForm('login');
  };

  return (
    <>
      <Typography
        variant="subtitle1"
        sx={{ color: 'grey', fontWeight: 450, marginBottom: '10px' }}
      >
        Thank you for registering with SkillMapping!
      </Typography>
      <Typography variant="body2" sx={{ color: 'grey', marginBottom: '10px' }}>
        Your request for access has been received. Our team will review your
        submission, and you will be notified once your account is approved.
      </Typography>
      <Typography variant="body2" sx={{ color: 'grey', marginBottom: '10px' }}>
        If you have any questions, please contact skillup@cybera.ca.
      </Typography>
      <Typography variant="body2" sx={{ color: 'grey' }}>
        We appreciate your patience.
      </Typography>
    </>
  );
};
