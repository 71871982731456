import { TextField, Button, Typography } from "@mui/material";

import { DropdownSelector } from "../../../../modules/dropdownSelector";

interface RegisterWithGoogleProps {
  userInfo: any;
  description: any;
  setDescription: any;
  institutionOptions: any;
  selectedInstitution: any;
  setSelectedInstitution: any;
  handleGoogleRegister: any;
}

export const RegisterWithGoogle = ({
  userInfo,
  description,
  setDescription,
  institutionOptions,
  selectedInstitution,
  setSelectedInstitution,
  handleGoogleRegister,
}: RegisterWithGoogleProps): JSX.Element => {
  return (
    <>
      <Typography
        variant="subtitle1"
        sx={{ color: "grey", textAlign: "center" }}
      >
        Welcome {userInfo.displayName.split(" ")[0]}! Please fill out your
        intended use and institution name.
      </Typography>
      <form>
        <TextField
          id="outlined-basic"
          label="Intended Use (Optional)"
          value={description}
          onChange={(e) => setDescription(e.target.value)}
          placeholder="Ex: Your role and purpose for using SkillUp"
          style={{ margin: "20px 0px", width: "100%" }}
          variant="outlined"
        />

        <DropdownSelector
          options={institutionOptions}
          id="institution-select"
          value={selectedInstitution}
          label={"Institution"}
          onChange={(value: any) => setSelectedInstitution(value)}
          group={"postSecondary"}
          dropdownStyles={null}
        />
      </form>
      <Button
        variant="contained"
        style={{ margin: "20px 0px" }}
        onClick={handleGoogleRegister}
        disabled={!selectedInstitution}
      >
        Register for Access
      </Button>
    </>
  );
};
