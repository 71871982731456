import { gql } from "@apollo/client";

import { styled } from "@mui/material/styles";
import ArrowForwardIosSharpIcon from "@mui/icons-material/ArrowForwardIosSharp";
import MuiAccordion, { AccordionProps } from "@mui/material/Accordion";
import MuiAccordionSummary, {
  AccordionSummaryProps,
} from "@mui/material/AccordionSummary";
import MuiAccordionDetails from "@mui/material/AccordionDetails";

import { SkillType } from "../../helper";
import { FixMeLater } from "../../../../any";

export interface OccupationType {
  name: string;
  description: string;
  skills: FixMeLater[];
}

export const PROGRAM_OCCUPATIONS = gql`
  query GetProgramOccupations($id: Int!) {
    program(id: $id) {
      cipTaxonomyByCipCode {
        cipCrosswalksByCipCode(first: 5) {
          nodes {
            lightcast {
              name
              description
              softSkillLast365Days(first: 50) {
                nodes {
                  soft_skill_id {
                    name
                    id
                  }
                  value
                }
              }
              hardSkillLast365Days {
                nodes {
                  hard_skill_id {
                    name
                    id
                  }
                  value
                }
              }
            }
          }
        }
      }
    }
    occupationLast365Days(first: 5, orderBy: VALUE_DESC) {
      nodes {
        occupation_id {
          name
          description
          softSkillLast365Days(first: 50) {
            nodes {
              soft_skill_id {
                name
                id
              }
              value
            }
          }
          hardSkillLast365Days {
            nodes {
              hard_skill_id {
                name
                id
              }
              value
            }
          }
        }
      }
    }
  }
`;

// Combine hard and soft skills, sort by value descending, remove duplicates
export const getSkills = (
  softSkills: FixMeLater[],
  hardSkills: FixMeLater[]
) => {
  let skills = softSkills
    .map((skill: FixMeLater) => ({
      id: skill.soft_skill_id.id,
      name: skill.soft_skill_id.name,
      type: "soft",
      value: skill.value,
    }))
    .concat(
      hardSkills.map((skill: FixMeLater) => ({
        id: skill.hard_skill_id.id,
        name: skill.hard_skill_id.name,
        type: "hard",
        value: skill.value,
      }))
    )
    .sort((a: SkillType, b: SkillType) => b.value - a.value)
    .slice(0, 100);

  skills = skills.filter(
    (value, index, self) =>
      index ===
      self.findIndex((t) => t.id === value.id && t.name === value.name)
  );

  return skills;
};

export const Accordion = styled((props: AccordionProps) => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
  borderTop: `1px solid ${theme.palette.divider}`,
  "&:first-of-type": {
    borderTop: 0,
  },
  "&:not(:last-child)": {
    borderBottom: 0,
  },
  "&::before": {
    display: "none",
  },
}));

export const AccordionSummary = styled((props: AccordionSummaryProps) => (
  <MuiAccordionSummary
    expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: "0.9rem" }} />}
    {...props}
  />
))(({ theme }) => ({
  backgroundColor:
    theme.palette.mode === "dark"
      ? "rgba(255, 255, 255, .05)"
      : "rgba(0, 0, 0, .03)",
  flexDirection: "row-reverse",
  "& .MuiAccordionSummary-expandIconWrapper.Mui-expanded": {
    transform: "rotate(90deg)",
  },
  "& .MuiAccordionSummary-content": {
    marginLeft: theme.spacing(1),
  },
}));

export const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  padding: theme.spacing(2),
  borderTop: "1px solid rgba(0, 0, 0, .125)",
}));
