import { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";

import { Grid, Stack, Skeleton, Drawer, Snackbar } from "@mui/material";
import { DataGrid, GridEventListener } from "@mui/x-data-grid";

import { gql, useLazyQuery, useMutation, useQuery } from "@apollo/client";
import { PHOTON_GRAPH_URL } from "../../environmentConfig";

import { columns } from "./dataGrid/dataColumns";
import { ProgramsType, getRows, noRows } from "./dataGrid/dataRows";
import { GridCustomToolbar } from "./dataGrid/dataFiltering";
import { ProgramInformation } from "./sidePanel";
import { AddProgramModal } from "./addProgram";

import useToken from "../../useToken";
import { FixMeLater } from "../../any";

import { Root, classes } from "./style";

const PROGRAM_LIST = gql`
  query GetProgramList($institution: String!) {
    programs(filter: { offered: { name: { equalTo: $institution } } }) {
      nodes {
        id
        created
        edited
        field
        name
        offered {
          id
          name
        }
        type
        duration
        createdBy {
          id
          firstname
          lastname
          photoUrl
        }
        lastEdited {
          id
          firstname
          lastname
          photoUrl
        }
        hardSkillCrosswalks {
          nodes {
            skill {
              name
            }
            id
            guaranteed
          }
        }
        softSkillCrosswalks {
          nodes {
            skill {
              name
            }
            id
            guaranteed
          }
        }
      }
    }
  }
`;

const INSERT_PROGRAM = gql`
  mutation AddProgram(
    $name: String!
    $type: String!
    $field: String!
    $cipCode: String!
    $duration: Int
    $offeredId: Int!
    $createdById: String!
  ) {
    insertProgram(
      input: {
        _name: $name
        _type: $type
        _field: $field
        _cipCode: $cipCode
        _duration: $duration
        _offeredId: $offeredId
        _createdById: $createdById
      }
    ) {
      boolean
    }
  }
`;

export const ProgramsScene = (): JSX.Element => {
  const { token } = useToken();
  const location = useLocation();

  const updatedSkills = location.state && location.state.updated;

  const [programs, setPrograms] = useState<ProgramsType[]>([]);
  const [institution] = useState(token.institution);
  const [institutionId, setInstitutionId] = useState(null);
  const [rows, setRows] = useState<FixMeLater[]>([]);
  const [open, setOpen] = useState(false);
  const [selectedProgram, setSelectedProgram] = useState<ProgramsType>();
  const [deletedProgram, setDeletedProgram] = useState(false);
  const [filterButtonEl, setFilterButtonEl] =
    useState<HTMLButtonElement | null>(null);
  const [openSnackbar, setOpenSnackbar] = useState<boolean>(false);
  const [snackbarMsg, setSnackbarMsg] = useState("");

  const [addProgram, setAddProgram] = useState(false);

  const [getPrograms] = useLazyQuery(PROGRAM_LIST, {
    context: { uri: PHOTON_GRAPH_URL },
    fetchPolicy: "cache-and-network",
  });

  const [insertProgram] = useMutation(INSERT_PROGRAM, {
    context: { uri: PHOTON_GRAPH_URL },
  });

  const { data, loading, refetch } = useQuery(PROGRAM_LIST, {
    context: { uri: PHOTON_GRAPH_URL },
    variables: { institution },
  });

  useEffect(() => {
    if (data) {
      let progs = data.programs.nodes.map(
        (program: FixMeLater, index: number) => ({
          id: index + 1,
          programId: program.id,
          created: program.created,
          edited: program.edited,
          field: program.field,
          name: program.name,
          institution: program.offered.name,
          type: program.type,
          duration: program.duration,
          createdBy: program.createdBy,
          lastEdited: program.lastEdited,
          hardSkills: program.hardSkillCrosswalks.nodes,
          softSkills: program.softSkillCrosswalks.nodes,
        })
      );

      setInstitutionId(data.programs.nodes[0].offered.id);
      setPrograms(progs);
      setRows(getRows(progs));
    }
  }, [data]);

  useEffect(() => {
    // Need to update the selectedProgram when the programs update
    if (selectedProgram) {
      let program = programs.find(
        (program: ProgramsType) => program.id === selectedProgram?.id
      );
      setSelectedProgram(program);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [programs]);

  const handleProgramSelection: GridEventListener<"rowClick"> = (params) => {
    setOpen(true);
    let program = programs.find((program) => program.id === params.id);
    setSelectedProgram(program);
  };

  const handleAddProgram = async (
    programName: string,
    field: string,
    type: string,
    duration: string
  ) => {
    const insert = await insertProgram({
      variables: {
        name: programName,
        type,
        field,
        cipCode: "24.0102", // TODO: Currently hard coded, but need to think about how we can get the cipCode when a user adds a program
        duration: duration ? duration : null,
        offeredId: institutionId,
        createdById: token.uid,
      },
    });

    if (insert.data.insertProgram.boolean) {
      getPrograms({
        variables: { institution },
        onCompleted: (data: FixMeLater) => {
          let progs = data.programs.nodes.map(
            (program: FixMeLater, index: number) => ({
              id: index + 1,
              programId: program.id,
              created: program.created,
              edited: program.edited,
              field: program.field,
              name: program.name,
              institution: program.offered.name,
              type: program.type,
              duration: program.duration,
              createdBy: program.createdBy,
              lastEdited: program.lastEdited,
              hardSkills: program.hardSkillCrosswalks.nodes,
              softSkills: program.softSkillCrosswalks.nodes,
            })
          );

          setPrograms(progs);
          setRows(getRows(progs));
        },
      });
    }

    setAddProgram(false);
  };

  useEffect(() => {
    if (deletedProgram) {
      setOpen(false);
      setDeletedProgram(false);
      // setRefreshUserList(true);
      refetch();
    } else if (updatedSkills) {
      refetch();
    }
  }, [deletedProgram, updatedSkills, refetch]);

  // Calculate page size based on viewport height
  const pageSize = Math.floor((window.innerHeight * 0.9) / 70);

  return (
    <Root>
      <Grid container spacing={2}>
        <Grid item xs={12} className={classes.container}>
          {programs.length === 0 || loading ? (
            <Stack spacing={1} sx={{ margin: "10px" }}>
              <Skeleton variant="text" sx={{ fontSize: "4.5rem" }} />
              <Skeleton variant="rounded" width="100%" height={40} />
              {[...Array(pageSize)].map((e, index) => (
                <Stack spacing={1} key={index}>
                  <Skeleton variant="rounded" width="100%" height={45} />
                </Stack>
              ))}
            </Stack>
          ) : (
            <DataGrid
              className={classes.dataGrid}
              rows={rows}
              columns={columns}
              slots={{
                toolbar: GridCustomToolbar,
                noResultsOverlay: noRows,
                noRowsOverlay: noRows,
              }}
              slotProps={{
                panel: {
                  anchorEl: filterButtonEl,
                  placement: "bottom-end",
                },
                toolbar: {
                  institution,
                  handleOpen: () => setAddProgram(true),
                  setFilterButtonEl,
                },
              }}
              onRowClick={handleProgramSelection}
              disableRowSelectionOnClick
              initialState={{
                pagination: {
                  paginationModel: {
                    pageSize: pageSize,
                  },
                },
                sorting: {
                  sortModel: [{ field: "dateAdded", sort: "desc" }],
                },
              }}
              pageSizeOptions={[pageSize]}
              disableVirtualization
            />
          )}
        </Grid>
      </Grid>
      <Drawer open={open} onClose={() => setOpen(false)} anchor="right">
        {selectedProgram && (
          <ProgramInformation
            setDeletedProgram={setDeletedProgram}
            refetchPrograms={refetch}
            selectedProgram={selectedProgram}
            setSnackbarMsg={setSnackbarMsg}
            setOpenSnackbar={setOpenSnackbar}
          />
        )}
      </Drawer>
      <AddProgramModal
        open={addProgram}
        handleClose={() => setAddProgram(false)}
        handleAddProgram={handleAddProgram}
        institution={institution}
      />
      <Snackbar
        open={openSnackbar}
        autoHideDuration={3000}
        message={snackbarMsg}
        onClose={() => setOpenSnackbar(false)}
      />
    </Root>
  );
};
