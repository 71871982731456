import { styled, Box } from "@mui/material";
import { FixMeLater } from "../../../any";

const PREFIX = "AddProgram";

export const classes = {
  title: `${PREFIX}-title`,
  container: `${PREFIX}-container`,
  programInfo: `${PREFIX}-programInfo`,
  boldText: `${PREFIX}-boldText`,
  buttonsContainer: `${PREFIX}-buttonsContainer`,
  button: `${PREFIX}-button`,
};

export const CustomBox = styled(Box)(({ theme }: FixMeLater) => ({
  [`& .${classes.title}`]: {
    textAlign: "center",
  },
  [`& .${classes.container}`]: {
    display: "flex",
    margin: "30px 0px",
  },
  [`& .${classes.programInfo}`]: {
    margin: "30px 0px",
  },
  [`& .${classes.boldText}`]: {
    display: "flex",
    alignItems: "center",
    fontWeight: 600,
  },
  [`& .${classes.buttonsContainer}`]: {
    display: "flex",
    justifyContent: "space-evenly",
  },
  [`& .${classes.button}`]: {
    width: "130px",
  },
}));
